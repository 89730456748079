<template>
  <li class="mail" :class="{ markedAsRead: mail.marked }">
    <div class="mail__header">
      <p>{{ mail.date }}, {{ mail.time }}</p>
      <h2>Od: {{ mail.mail }}</h2>
      <h2>{{ mail.name }}</h2>

      <h3>Tel: {{ mail.tel }}</h3>
    </div>
    <div class="mail__message">
      {{ mail.message }}
    </div>
  </li>
</template>

<script>
export default {
  props: ["mail"],
};
</script>

<style scoped>
.mail {
  background-color: var(--base-dark);
  padding: 2rem;
  border-radius: 2rem;
  box-shadow: 0 0 1rem var(--base-dark-light-3);
  transition: 0.3s ease;
  cursor: pointer;

  border-top: 3px solid green;

  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.mail.markedAsRead {
  border-top: 3px solid orangered;
}

.mail:hover {
  transform: scale(1.02);
  box-shadow: 0 0 1rem var(--base-dark-light-1);
}

.mail__header {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.mail__header p {
  font-size: 1.6rem;
  color: #ffffff71;
}

.mail__header h2 {
  font-size: 2rem;
  color: var(--base-yellow);
  font-weight: 500;
}

.mail__header h3 {
  font-size: 1.6rem;
  color: var(--base-yellow-light-1);
  font-weight: 500;
}

.mail__message {
  font-size: 1.6rem;
  color: #fff;
}
</style>
