<template>
  <div>
    <header-section></header-section>
    <offers-section></offers-section>
    <why-section></why-section>
    <coop-section></coop-section>
    <portfolio-section></portfolio-section>
  </div>
</template>

<script>
import HeaderSection from "../components/home/HeaderSection.vue";
import OffersSection from "../components/home/OffersSection.vue";
import WhySection from "../components/home/WhySection/WhySection.vue";
import CoopSection from "../components/home/CoopSection.vue";
import PortfolioSection from "../components/home/PortfolioSection/PortfolioSection.vue";

export default {
  components: {
    "offers-section": OffersSection,
    "why-section": WhySection,
    "coop-section": CoopSection,
    "header-section": HeaderSection,
    "portfolio-section": PortfolioSection,
  },
};
</script>
