<template>
  <h4 :class="{ light: mode === 'light', dark: mode === 'dark' }">
    - <slot></slot> -
  </h4>
</template>

<script>
export default {
  props: {
    mode: {
      type: String,
      required: false,
      default: "light",
    },
  },
};
</script>

<style scoped>
h4 {
  color: #ffd90025;
  font-size: 2rem;
  font-weight: 400;
  letter-spacing: 0.5rem;
  margin-bottom: 2rem;
}

.light {
  color: #ffd90025;
}

.dark {
  color: #0c0f15ab;
}
</style>
