import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAYpOaZdyTXio8nQAybCPPzBsWZ5fUg0fo",
  authDomain: "goatweb-blog.firebaseapp.com",
  databaseURL:
    "https://goatweb-blog-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "goatweb-blog",
  storageBucket: "goatweb-blog.appspot.com",
  messagingSenderId: "368853940795",
  appId: "1:368853940795:web:5ec578c3ffab8dfa98ba1e",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default getStorage(app);
