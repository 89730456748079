import { createStore } from "vuex";
import WhyModule from "./modules/why/index.js";
import ProjectsModule from "./modules/portfolio/index.js";
import ContactModule from "./modules/contact/index.js";
import authModule from "./modules/auth/index.js";
import postsModule from "./modules/posts/index.js";

export default createStore({
  modules: {
    why: WhyModule,
    portfolio: ProjectsModule,
    contact: ContactModule,
    auth: authModule,
    posts: postsModule,
  },
});
