import getters from "./getters";

export default {
  namespaced: true,
  state() {
    return {
      reasons: [
        {
          id: 0,
          iconName: "promotion",
          title: "Promocja i widoczność",
          desc: "Posiadanie strony internetowej umożliwia efektywną promocję Twojego biznesu lub działalności. Dzięki niej możesz dotrzeć do szerokiego grona potencjalnych klientów i partnerów biznesowych, niezależnie od ich lokalizacji. Twoja strona działa dla Ciebie 24 godziny na dobę, 7 dni w tygodniu, co pozwala na ciągłe budowanie świadomości marki i pozyskiwanie nowych klientów",
        },
        {
          id: 1,
          iconName: "trust",
          title: "Wiarygodność i zaufanie",
          desc: "Posiadanie profesjonalnej strony internetowej dodaje Twojej działalności wiarygodności. W dzisiejszych czasach, gdy większość konsumentów wyszukuje informacji online, brak obecności w sieci może być postrzegany jako brak profesjonalizmu. Strona internetowa pozwala udostępnić informacje o swojej firmie, ofercie, referencjach, certyfikatach czy opinii klientów, co buduje zaufanie i pozytywne wrażenie",
        },
        {
          id: 2,
          iconName: "communication",
          title: "Komunikacja i kontakt",
          desc: "Strona internetowa zapewnia łatwy sposób komunikacji z klientami. Możesz udostępnić formularz kontaktowy, dane teleadresowe czy chat online, umożliwiając potencjalnym klientom szybki i wygodny kontakt z Tobą. Dodatkowo, możesz również udostępnić informacje o swoich godzinach pracy, lokalizacji czy oferowanych usługach, co ułatwia klientom znalezienie potrzebnych informacji",
        },
        {
          id: 3,
          iconName: "presentation",
          title: "Prezentacja produktów i usług",
          desc: " Strona internetowa to doskonałe narzędzie do prezentacji swoich produktów lub usług. Możesz przedstawić ich szczegółowe opisy, zdjęcia, ceny, a nawet wideo czy opinie klientów. Dzięki temu potencjalni klienci mają możliwość zapoznania się z Twoją ofertą przed podjęciem decyzji zakupowych",
        },
        {
          id: 4,
          iconName: "company",
          title: "Rozbudowanie swojej marki",
          desc: "Posiadanie własnej strony internetowej pozwala na budowanie swojej marki. Możesz zaprezentować swoje wartości, misję, historię czy unikalne cechy, które wyróżniają Twoją firmę na tle konkurencji. Dzięki temu tworzysz spójny wizerunek i budujesz rozpoznawalność marki",
        },
        {
          id: 5,
          iconName: "big-parcel",
          title: "Sprzedaż online",
          desc: "Jeśli prowadzisz działalność handlową, strona internetowa daje Ci możliwość sprzedaży produktów lub usług online. Możesz zintegrować sklep internetowy, umożliwiając klientom łatwe i wygodne dokonywanie zakupów przez Internet. To otwiera nowe możliwości rozwoju biznesu i dotarcia do szerszego grona klientów",
        },
      ],
    };
  },

  getters,
};
