<template>
  <base-container>
    <header>
      <div class="header-content">
        <router-link class="header-content__img-a" to="/"
          ><img
            src="../imgs/logo-color.webp"
            alt="GoatWeb Solutions"
            loading="lazy"
        /></router-link>

        <div
          class="header-content__img-container"
          :class="{ 'nav-active': isVisible }"
        >
          <img
            class="header-content__img"
            src="../imgs/logo-color.webp"
            alt="GoatWeb Solutions"
            @click="triggerNav"
            loading="lazy"
          />
        </div>

        <nav class="nav-main">
          <ul>
            <li><router-link to="/">Home</router-link></li>
            <li>
              <router-link to="/uslugi">Usługi</router-link>
            </li>
            <li>
              <router-link to="/o-mnie">O mnie</router-link>
            </li>
            <li>
              <router-link to="/portfolio">Portfolio</router-link>
            </li>
            <li>
              <router-link to="/blog">Blog</router-link>
            </li>
            <li>
              <router-link to="/kontakt">Kontakt</router-link>
            </li>
            <li v-if="isLoggedIn">
              <router-link to="/website-editor" @click="closeNav"
                >Website Editor</router-link
              >
            </li>

            <li v-if="isLoggedIn">
              <button class="logout-btn" @click="logout">Wyloguj</button>
            </li>
          </ul>
        </nav>

        <transition name="nav">
          <nav class="nav-mobile" v-if="isVisible">
            <ul>
              <li>
                <router-link to="/" @click="closeNav">Home</router-link>
              </li>

              <li>
                <router-link to="/uslugi" @click="closeNav">Usługi</router-link>
              </li>

              <li>
                <router-link to="/o-mnie" @click="closeNav">O mnie</router-link>
              </li>

              <li>
                <router-link to="/portfolio" @click="closeNav"
                  >Portfolio</router-link
                >
              </li>

              <li>
                <router-link to="/blog" @click="closeNav">Blog</router-link>
              </li>

              <li>
                <router-link to="/kontakt" @click="closeNav"
                  >Kontakt</router-link
                >
              </li>

              <li v-if="isLoggedIn">
                <router-link to="/website-editor" @click="closeNav"
                  >Website Editor</router-link
                >
              </li>

              <li v-if="isLoggedIn">
                <button class="logout-btn" @click="logout">Wyloguj</button>
              </li>
            </ul>
          </nav>
        </transition>
      </div>
    </header>
  </base-container>
</template>

<script>
export default {
  data() {
    return {
      isVisible: false,
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters["isAuthenticated"];
    },
  },
  methods: {
    triggerNav() {
      this.isVisible = !this.isVisible;
    },
    closeNav() {
      this.isVisible = false;
    },
    logout() {
      this.$store.dispatch("logout");

      if (this.$router.currentRoute._value.path === "/website-editor") {
        this.$router.replace("/login");
      }
    },
  },
};
</script>

<style scoped>
header {
  z-index: 10;
  background: linear-gradient(
    to right,
    rgba(19, 18, 18, 0.911) 0% 20%,
    #ffd900e7 60% 100%
  );
  padding: 1rem 1.5rem;
  border-radius: 10rem;

  position: fixed;
  max-width: 118rem;
  width: calc(100% - 4rem);
  top: 3rem;
  border: 2px solid #ffd800;
  transition: 0.3s ease;

  transform: translateX(-50%);
}

header img {
  width: 8rem;
  height: 8rem;
  transition: 0.3s ease;
}

.header-content__img-container {
  display: none;
}

header img:hover {
  transform: rotate(-15deg);
}

.header-content {
  display: flex;
  align-items: center;
  transition: 0.3s ease;
}

nav {
  margin-left: auto;

  transition: 1s ease;
}

.nav-mobile {
  display: none;
}

nav ul {
  list-style: none;
  display: flex;
  gap: 1.5rem;
  align-items: center;

  transition: 0.6s ease-in;
}

nav ul li {
  font-size: 1.8rem;
}

.logout-btn {
  background: none;
  outline: none;
  border: none;
  padding: 0;
  margin: 0;

  font-family: inherit;

  cursor: pointer;
  position: relative;
  padding: 0.8rem;
  text-decoration: none;
  color: #0c0f15;
  font-size: 1.8rem;

  transition: 0.3s ease;
}

nav ul a::before,
.logout-btn::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 0%;
  height: 0.2rem;
  bottom: 0;
  background-color: #0c0f15;

  transition: 0.2s ease;
}

nav ul a:link,
nav ul a:visited {
  position: relative;
  padding: 0.8rem;
  text-decoration: none;
  color: #0c0f15;

  transition: 0.3s ease;
}

nav ul a:hover::before,
.logout-btn:hover::before {
  width: 80%;
}

.nav-enter-from,
.nav-leave-to {
  transform: translateY(-6rem);
  opacity: 0;
}

.nav-enter-to,
.nav-leave-from {
  transform: translateY(0);
  opacity: 1;
}

.nav-enter-active,
.nav-leave-active {
  transition: 0.3s ease;
}

/* 900px */
@media only screen and (max-width: 56.25em) {
  header {
    background: none;
    padding: 1rem;
    border: none;

    width: 11rem;
    top: 2rem;
    right: 2rem;
    transform: translateX(0);
  }

  /* header img {
    filter: drop-shadow(0rem 0rem 0.5rem var(--base-dark-light-3));
  } */

  header img:hover {
    transform: rotate(0);
  }

  .nav-active img {
    transform: rotate(-15deg);
  }

  .nav-mobile {
    display: flex;
    background-color: var(--base-yellow);
  }

  .nav-main {
    display: none;
  }

  .header-content__img-a {
    display: none;
  }

  .header-content__img-container {
    z-index: 8;
    display: grid;
    place-items: center;
    cursor: pointer;

    background: var(--base-yellow);
    box-shadow: 0 0 1rem var(--base-dark-light-1);
    width: 11rem;
    height: 11rem;
    border-radius: 3rem;
  }

  .header-content {
    flex-direction: column;
    align-items: center;
  }

  nav {
    z-index: 5;
    margin-left: 0;
    width: 11rem;
    padding: 4rem 0 2rem 0;
    border-bottom-right-radius: 3rem;
    border-bottom-left-radius: 3rem;
    box-shadow: 0 0 1rem var(--base-dark-light-1);

    margin-top: -3rem;
  }

  nav ul {
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    width: 100%;
  }

  nav ul li a {
    text-align: center;
  }

  nav ul li {
    color: var(--base-dark);

    display: grid;
    place-items: center;
  }
}

/* 600px */
@media only screen and (max-width: 37.5em) {
  header img {
    width: 8rem;
    height: 8rem;
  }

  .header-content__img-container {
    width: 10rem;
    height: 10rem;
  }

  nav {
    width: 10rem;
  }
}

/* 400px */
@media only screen and (max-width: 25em) {
  header img {
    width: 6rem;
    height: 6rem;
  }

  .header-content__img-container {
    width: 8rem;
    height: 8rem;
  }

  nav {
    width: 8rem;
  }

  nav ul li,
  nav ul li {
    font-size: 1.6rem;
  }
}
</style>
