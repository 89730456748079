<template>
  <div class="about-page__desc">
    <h2>
      <strong>Tworzenie stron, aplikacji, sklepów i projektów webowych</strong
      ><br />
      to moja pasja i zawodowe powołanie. Przez ponad 2 lata aktywnie działam w
      branży, zdobywając cenne doświadczenie i realizując kilkanaście
      różnorodnych projektów, które możesz zobaczyć w moim
      <a href="/portfolio">portfolio</a>.
    </h2>

    <div>
      <h3>
        <strong>
          Dwie pasje - jazda na moto i eksploracją opuszczonych miejsc (urbex)
        </strong>
        <br />te aktywności dają mi energię i inspirację do kreatywnego
        podejścia do mojej pracy...<br />
        i jestem również studentem informatyki na Politechnice Poznańskiej -
        pozwala mi na ciągły rozwój i poszerzanie mojej wiedzy.
      </h3>

      <h3>
        Zapraszam do odwiedzenia mojego <a href="/blog">bloga</a>, gdzie dzielę
        się moimi przygodami i refleksjami związanymi z moimi hobby.
      </h3>
    </div>

    <div>
      <h3>
        <strong>Jako freelancer jestem elastyczny i gotowy</strong>
        <br />by sprostać Twoim potrzebom. Moje usługi są skrojone na miarę, aby
        dostarczyć rozwiązania idealnie dopasowane do Twoich wymagań.
      </h3>

      <h3>
        Jeśli potrzebujesz profesjonalnego podejścia i skutecznych rozwiązań,
        skontaktuj się ze mną za pomocą
        <a href="/kontakt">formularza kontaktowego</a>.<br />
        Czekam na szansę, aby stworzyć coś wyjątkowego razem!
      </h3>
    </div>
  </div>
</template>

<style scoped>
a:link,
a:visited {
  color: var(--base-yellow);
  text-decoration: none;
  border-bottom: 1px solid var(--base-yellow);
  font-size: inherit;
  padding: 2px;

  transition: 0.3s ease;
}

a:hover {
  background-color: var(--base-yellow);
  color: var(--base-dark);
}

a:active {
  background-color: var(--base-yellow-light-1);
}

.about-page__desc {
  color: #fff;

  display: flex;
  flex-direction: column;
  gap: 6rem;

  line-height: 2.5rem;
}

.about-page__desc h2 {
  font-size: 2rem;
  font-weight: 500;
}

.about-page__desc h2 strong {
  font-size: 2.2rem;
  background-color: var(--base-yellow);
  color: var(--base-dark);
  padding: 0.1rem;
}

.about-page__desc h3 {
  font-size: 1.8rem;
  font-weight: 500;
}

.about-page__desc h3 strong {
  font-size: 2rem;
  background-color: var(--base-yellow);
  color: var(--base-dark);
  padding: 0.1rem;
}

.about-page__desc div {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

/* 700px */
@media only screen and (max-width: 43.75em) {
  a:link,
  a:visited {
    font-size: 1.6rem;
  }

  .about-page__desc h2 {
    font-size: 1.8rem;
  }

  .about-page__desc h2 strong {
    font-size: 2rem;
  }

  .about-page__desc h3 {
    font-size: 1.6rem;
  }

  .about-page__desc h3 strong {
    font-size: 1.8rem;
  }
}
</style>
