<template>
  <router-link :to="postDetailsLink" class="blog-page__details-link">
    <div class="blog-page__post">
      <div class="blog-page__post-main_content">
        <div class="blog-page__post-header">
          <div class="blog-page__post-header__date">
            <p>{{ postData.date }}</p>
          </div>
          <div class="blog-page__post-header__title">
            <h2>
              {{ postData.title }}
            </h2>
          </div>
        </div>
        <div class="blog-page__post-content">
          <h3>
            {{ shortDesc }}
            <router-link :to="postDetailsLink">czytaj więcej</router-link>
          </h3>
        </div>

        <ul class="blog-page__tags">
          <li v-for="tag in postData.tags" :key="tag">
            <strong>{{ tag }}</strong>
          </li>
        </ul>
      </div>

      <base-spinner class="img-spinner" v-if="isLoading"></base-spinner>
      <img v-else lazy :src="thumbnailURL" alt="Post thumbnail" />
    </div>
  </router-link>
</template>

<script>
import storage from "../../firebase";
import { ref, getDownloadURL } from "firebase/storage";

export default {
  props: ["postData"],
  data() {
    return {
      thumbnailURL: "",
      imgsURL: [],
      isLoading: false,
    };
  },
  computed: {
    postDetailsLink() {
      return this.$route.path + "/" + this.postData.title;
    },
    shortDesc() {
      return `${this.postData.desc.slice(0, 100)}... `;
    },
  },
  methods: {
    async downloadURL() {
      await getDownloadURL(ref(storage, this.postData.thumbnailURL)).then(
        (downloadURL) => {
          this.thumbnailURL = downloadURL;
          this.$store.commit("posts/setThumbnailURL", {
            tnURL: downloadURL,
            id: this.postData.id,
          });
        }
      );
    },
  },

  async created() {
    this.isLoading = true;
    await this.downloadURL();
    this.isLoading = false;
  },
};
</script>

<style scoped>
.blog-page__details-link:link,
.blog-page__details-link:active {
  text-decoration: none;
}

.blog-page__post {
  display: flex;
  align-items: center;
  gap: 2rem;
  background-color: var(--base-dark-light-1);
  padding: 2rem;
  border-radius: 2rem;
  width: 100%;

  box-shadow: 0 0 1rem var(--base-dark-light-2);
  transition: 0.3s ease;
}

.blog-page__post:hover {
  transform: scale(1.01);
  box-shadow: 0 0 1rem var(--base-dark-light-3);
}

.blog-page__post img,
.img-spinner {
  width: 20rem;
  border-radius: 2rem;
  margin-left: auto;
}

.blog-page__post-main_content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.blog-page__post-header {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.blog-page__post-header__date {
  color: #ffffff8a;
  font-size: 1.6rem;
  font-weight: 200;
  letter-spacing: 0.2rem;
}

.blog-page__post-header__date p::before {
  display: inline-block;
  width: 2rem;
  height: 0.1rem;
  background-color: var(--base-yellow);
  content: "";
  margin-bottom: 0.5rem;
  margin-right: 1rem;
}

.blog-page__post-header__title h2 {
  font-size: 2rem;
  color: var(--base-yellow);
  font-weight: 600;
}

.blog-page__post-content a:link,
.blog-page__post-content a:visited {
  color: var(--base-yellow);
  font-size: inherit;

  transition: 0.3s ease;
}

.blog-page__post-content a:hover {
  opacity: 0.7;
}

.blog-page__post-content h3 {
  color: #fff;
  font-size: 1.8rem;
  font-weight: 500;
}

.blog-page__tags {
  list-style: none;
  display: flex;
  gap: 1rem;
}

.blog-page__tags li {
  margin-top: 2rem;
  font-size: 1.6rem;
  color: var(--base-yellow-light-1);
  text-transform: capitalize;
}

.blog-page__tags li strong {
  font-weight: 500;
}

/* 600px */
@media only screen and (max-width: 37.5em) {
  .blog-page__post {
    flex-direction: column;
    gap: 4rem;
  }

  .blog-page__post img,
  .img-spinner {
    width: 30rem;
    margin-left: 0;
  }
}

/* 400px */
@media only screen and (max-width: 25em) {
  .blog-page__post img {
    width: 20rem;
  }

  .blog-page__post-header__date p {
    font-size: 1.4rem;
  }

  .blog-page__post-header__date p::before {
    width: 1.4rem;
    margin-bottom: 0.4rem;
    margin-right: 0.8rem;
  }

  .blog-page__post-header__title h2 {
    font-size: 1.9rem;
  }

  .blog-page__post-content h3 {
    font-size: 1.6rem;
  }
}
</style>
