<template>
  <section class="why-section">
    <div class="dark-layout"></div>
    <base-container>
      <h2>Dlaczego warto mieć swoją stronę internetową?</h2>

      <ul class="why-section__list">
        <why-reason
          v-for="reason in reasons"
          :key="reason.id"
          :id="reason.id"
          :icon-name="reason.iconName"
          :title="reason.title"
          :desc="reason.desc"
        ></why-reason>
      </ul>
    </base-container>
  </section>
</template>

<script>
import WhyReason from "./WhyReason.vue";

export default {
  components: { "why-reason": WhyReason },
  data() {
    return {
      reasons: [],
    };
  },
  created() {
    this.reasons = this.$store.getters["why/getReasons"];
  },
};
</script>

<style scoped>
.why-section {
  background-color: var(--base-dark);
  margin-top: 8rem;
  padding: 5rem 0;
  position: relative;

  background-image: url(../../../imgs/why-section-bg.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.dark-layout {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  display: inline-block;
  background-color: #0c0f15ef;
}

.why-section h2 {
  font-size: 4rem;
  font-weight: 600;
  color: var(--base-yellow);
  margin-bottom: 8rem;
  text-align: center;
}

.why-section__list {
  list-style: none;
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  gap: 8rem;
}

/* 700px */
@media only screen and (max-width: 43.75em) {
  .why-section h2 {
    font-size: 3.2rem;
  }
}
</style>
