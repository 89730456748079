<template>
  <section class="post-details__section">
    <base-container>
      <div class="post-details__container">
        <div class="post-details__header">
          <p class="post__detail-date">{{ selectedPost.date }}</p>
          <h2 class="post__detail-title">{{ selectedPost.title }}</h2>
        </div>

        <h3 class="post__detail-desc">{{ selectedPost.desc }}</h3>

        <div class="post-details__imgs">
          <img
            :src="selectedPost.tnURL"
            alt="Miniaturka postu"
            class="post__detail-tn"
          />
        </div>
      </div>
    </base-container>
  </section>
</template>

<script>
export default {
  props: ["post"],
  data() {
    return {
      selectedPost: null,
    };
  },
  created() {
    this.selectedPost = this.$store.getters["posts/getPosts"].find(
      (post) => post.title === this.post
    );
  },
};
</script>

<style scoped>
.post-details__section {
  background-color: var(--base-dark);
  padding: 20rem 0;
  margin-bottom: -10rem;
}

.post-details__container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.post-details__header {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.post__detail-date {
  color: #ffffff8a;
  font-size: 1.6rem;
  font-weight: 200;
  letter-spacing: 0.2rem;
}

.post__detail-title {
  font-size: 2.6rem;
  color: var(--base-yellow);
  font-weight: 500;
}

.post__detail-desc {
  font-size: 1.6rem;
  color: #fff;
  font-weight: 500;
}

.post-details__imgs {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  gap: 2rem;
}

.post-details__imgs img {
  width: 100%;
  height: 100%;

  box-shadow: 0 0 1rem var(--base-dark-light-1);
  border-radius: 1rem;
}

/* 900px */
@media only screen and (max-width: 56.25em) {
  .post-details__section {
    padding: 10rem 0 20rem 0;
  }
}
</style>
