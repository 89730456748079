<template>
  <ul class="contact-page__section-info">
    <li>
      <img src="../../imgs/icons/call-black.webp" alt="" loading="lazy" />
      <div>
        <h2>Telefon</h2>
        <h4><a href="tel:692251700">+48 692 251 700</a></h4>
      </div>
    </li>

    <li>
      <img src="../../imgs/icons/mail-black.webp" alt="" loading="lazy" />
      <div>
        <h2>E-mail</h2>
        <h4>
          <a href="mailto:jakubkozaneckidev@gmail.com"
            >jakubkozaneckidev@gmail.com</a
          >
        </h4>
      </div>
    </li>

    <li>
      <img src="../../imgs/icons/clock-black.webp" alt="" loading="lazy" />
      <div>
        <h2>Godziny pracy</h2>
        <h4>Pon.-Niedz. 8:00 - 20:00</h4>
      </div>
    </li>
  </ul>
</template>

<style scoped>
.contact-page__section-info {
  list-style: none;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 4rem;
  column-gap: 3rem;
}

.contact-page__section-info li {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.contact-page__section-info li img {
  width: 50px;
  height: 50px;
}

.contact-page__section-info div {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.contact-page__section-info div h2 {
  font-size: 2rem;
  font-weight: 500;
}

.contact-page__section-info div h4 a:link,
.contact-page__section-info div h4 a:visited {
  font-size: 1.8rem;
  color: var(--base-yellow);
  text-decoration: none;
  transition: 0.3s ease;
}

.contact-page__section-info div h4 a:hover {
  filter: drop-shadow(0 0 0.1rem var(--base-yellow-light-1));
}

.contact-page__section-info div h4 {
  font-size: 1.8rem;
  color: var(--base-yellow);
}
</style>
