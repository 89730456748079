<template>
  <section class="about-page__section">
    <base-container>
      <div class="about-page__container">
        <about-page-img></about-page-img>
        <about-page-desc></about-page-desc>
      </div>
      <about-page-skills></about-page-skills>
    </base-container>
  </section>
</template>

<script>
import AboutPageImg from "./AboutPageImg.vue";
import AboutPageDesc from "./AboutPageDesc.vue";
import AboutPageSkills from "./AboutPageSkills.vue";

export default {
  components: {
    "about-page-img": AboutPageImg,
    "about-page-desc": AboutPageDesc,
    "about-page-skills": AboutPageSkills,
  },
};
</script>

<style scoped>
.about-page__section {
  padding: 20rem 0;
  margin-bottom: -10rem;

  background-color: var(--base-dark);
}

.about-page__container {
  display: flex;
  gap: 4rem;
  align-items: center;
  padding-left: 2rem;
}

/* 900px */
@media only screen and (max-width: 56.25em) {
  .about-page__section {
    padding: 15rem 0 20rem 0;
  }

  .about-page__container {
    flex-direction: column;
    gap: 8rem;
  }
}
</style>
