import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import VueGtag from "vue-gtag";
import store from "./store/index.js";
import TheHeader from "./layout/TheHeader";
import TheFooter from "./layout/TheFooter/TheFooter";
import BaseContainer from "./components/BaseContainer";
import BaseButton from "./components/BaseButton";
import BaseSectionTitle from "./components/BaseSectionTitle";
import BaseSpinner from "./components/BaseSpinner";

const app = createApp(App);

app.use(router);
app.use(VueGtag, {
  config: {
    id: "G-GKDH9Y1SMM",
  },
});
app.use(store);
app.component("the-header", TheHeader);
app.component("the-footer", TheFooter);
app.component("base-container", BaseContainer);
app.component("base-button", BaseButton);
app.component("base-section-title", BaseSectionTitle);
app.component("base-spinner", BaseSpinner);

app.mount("#app");
