<template>
  <section class="new-post__section">
    <base-container>
      <div class="new-post__container">
        <input
          :class="{ error: !this.date.isValid }"
          type="date"
          name="data"
          id="date"
          v-model="date.inputValue"
          @change="checkDate"
        />
        <input
          :class="{ error: !this.title.isValid }"
          type="text"
          name="title"
          id="title"
          placeholder="Tytuł posta"
          v-model="title.inputValue"
          @input="checkTitle"
        />
        <textarea
          :class="{ error: !this.description.isValid }"
          cols="30"
          rows="10"
          name="description"
          id="description"
          placeholder="Główny tekst posta"
          v-model="description.inputValue"
          @input="checkDesc"
        ></textarea>

        <div class="tags-container">
          <input
            :class="{ error: !this.tags.isValid }"
            type="text"
            name="tags"
            id="tags"
            placeholder="Tagi posta"
            v-model="tags.inputValue"
            @keydown.enter="addTag"
          />

          <ul class="entered-tags" v-if="tags.tags.length > 0">
            <li v-for="tag in tags.tags" :key="tag" @click="deleteTag(tag)">
              {{ tag }}
            </li>
          </ul>
        </div>

        <div class="new-post__container-files">
          <div>
            <label for="main-img">Miniaturka posta</label>
            <input
              type="file"
              name="main-img"
              id="main-img"
              placeholder="Zdjęcie główne posta"
              ref="thumbnail"
              :class="{ error: !this.thumbnail.isValid }"
              @change="checkThumbnail"
            />
          </div>
          <div>
            <label for="imgs">Zdjęcia</label>
            <input
              type="file"
              name="imgs"
              id="imgs"
              multiple
              ref="imgs"
              :class="{ error: !this.imgs.isValid }"
              @change="checkImgs"
            />
          </div>
        </div>
        <div class="new-post__form-submit-btn" v-if="!isLoading">
          <base-button mode="outline-dark" @click="submitForm"
            >Wstaw post</base-button
          >
        </div>
        <div class="spinner" v-if="isLoading">
          <div class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>

        <div class="error-container" v-if="err">
          <h2 class="error-occured">{{ err }}</h2>
        </div>
      </div>
    </base-container>
  </section>
</template>

<script>
import storage from "../../../firebase.js";
import { ref, uploadBytes } from "firebase/storage";

export default {
  data() {
    return {
      date: {
        inputValue: "",
        isValid: true,
      },
      title: {
        inputValue: "",
        isValid: true,
      },
      description: {
        inputValue: "",
        isValid: true,
      },
      tags: {
        inputValue: "",
        tags: [],
        isValid: true,
      },
      thumbnail: {
        isValid: true,
      },
      imgs: {
        isValid: true,
      },
      err: "",
      isLoading: false,
    };
  },
  methods: {
    addTag() {
      if (!this.tags.inputValue) return;
      this.tags.tags.push(String(this.tags.inputValue).toLowerCase());
      this.checkTags();
      this.tags.inputValue = "";
    },
    deleteTag(selectedTag) {
      this.tags.tags.find((tag, i) => {
        if (tag === selectedTag) {
          this.tags.tags.splice(i, 1);
        }
      });

      this.checkTags();
    },
    checkDate() {
      this.date.isValid = true;

      if (!this.date.inputValue) this.date.isValid = false;
    },
    checkTitle() {
      this.title.isValid = true;

      if (this.title.inputValue.trim().length < 1) this.title.isValid = false;
    },
    checkDesc() {
      this.description.isValid = true;

      if (this.description.inputValue.trim().length < 1)
        this.description.isValid = false;
    },
    checkTags() {
      this.tags.isValid = true;

      if (this.tags.tags.length < 1) this.tags.isValid = false;
    },
    checkThumbnail() {
      this.thumbnail.isValid = true;

      if (this.$refs.thumbnail.files.length === 0)
        this.thumbnail.isValid = false;
    },
    checkImgs() {
      this.imgs.isValid = true;

      if (this.$refs.imgs.files.length === 0) this.imgs.isValid = false;
    },
    validateData() {
      this.checkDate();
      this.checkTitle();
      this.checkDesc();
      this.checkTags();
      this.checkThumbnail();
      this.checkImgs();

      if (
        this.date.isValid &&
        this.title.isValid &&
        this.description.isValid &&
        this.tags.isValid &&
        this.thumbnail.isValid &&
        this.imgs.isValid
      ) {
        return true;
      }
      return false;
    },
    clearInputs() {
      this.date.inputValue = "";
      this.title.inputValue = "";
      this.description.inputValue = "";
      this.tags.inputValue = "";
      this.tags.tags = [];
      this.$refs.thumbnail.value = null;
      this.$refs.imgs.value = null;
    },
    async submitForm() {
      this.isLoading = true;
      if (this.validateData()) {
        const postData = {
          date:
            new Date(this.date.inputValue).toLocaleDateString("pl-PL", {
              day: "numeric",
              month: "long",
            }) +
            ", " +
            new Date(this.date.inputValue).toLocaleDateString("pl-PL", {
              year: "numeric",
            }),
          title: this.title.inputValue,
          desc: this.description.inputValue,
          tags: this.tags.tags,
        };
        try {
          const postID = await this.$store.dispatch(
            "posts/sendDataToRDB",
            postData
          );

          this.uploadThumbnail(this.$refs.thumbnail.files[0], postID);
          this.uploadImgs(this.$refs.imgs.files, postID);

          this.$router.replace("/website-editor");
        } catch (err) {
          this.err =
            "Wystąpił nieoczekiwany błąd - prawdopodobnie po stronie bazy danych";
        }

        this.clearInputs();
      }
      this.isLoading = false;
    },
    uploadThumbnail(tn, postID) {
      const storageRef = ref(storage, `${postID}/thumbnail.webp`);
      uploadBytes(storageRef, tn);
    },
    uploadImgs(imgs, postID) {
      for (let i = 0; i < imgs.length; i++) {
        const storageRef = ref(storage, `${postID}/imgs/${i}.webp`);
        uploadBytes(storageRef, imgs[i]);
      }
    },
  },
};
</script>

<style scoped>
.error,
.error::placeholder {
  color: red !important;
}

.error {
  border-bottom: 2px solid red !important;
}

.error-occured {
  font-weight: 500;
  color: red;
}

.new-post__container {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  background-color: var(--base-yellow);
  padding: 2rem;
  border-radius: 1rem;

  box-shadow: 0 0 1rem var(--base-yellow-light-1);
}

.new-post__container input#date {
  width: 20rem;
}

.new-post__container input,
.new-post__container textarea {
  border: none;
  outline: none;
  background: none;

  padding: 0.5rem;
  background-color: #a2a2a21e;
  border-bottom: 2px solid var(--base-dark);
}

.new-post__container input::placeholder,
.new-post__container textarea::placeholder,
.new-post__container input,
.new-post__container textarea {
  font-size: 1.6rem;
  font-family: "Inter Tight", sans-serif;
  color: var(--base-dark);
}

.new-post__container textarea {
  resize: vertical;
}

.new-post__container input button {
  background: none;
}

.tags-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 2rem 0;
}

.entered-tags {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.entered-tags li {
  border-radius: 1rem;
  font-size: 1.6rem;
  padding: 0.5rem 1rem;
  background-color: var(--base-dark);
  color: var(--base-yellow);
  cursor: pointer;
  box-shadow: 0 0 0.5rem var(--base-dark-light-1);
}

.new-post__container-files {
  margin-top: 4rem;
  display: flex;
  gap: 4rem;
  justify-content: center;
}

.new-post__container-files div {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.new-post__container-files div label {
  font-size: 1.6rem;
}

.new-post__form-submit-btn {
  margin-top: 5rem;

  display: flex;
  justify-content: center;
}

.new-post__form-submit-btn button {
  width: 40%;
}

.spinner {
  text-align: center;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 120px;
  height: 120px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid var(--base-dark);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--base-dark) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

/* 800px */
@media only screen and (max-width: 50em) {
  .new-post__container-files {
    flex-direction: column;
    gap: 4rem;
    align-items: flex-start;
  }
}

/* 500px */
@media only screen and (max-width: 31.25em) {
  .new-post__container input {
    width: 100%;
  }

  .new-post__form-submit-btn button {
    width: auto;
  }
}
</style>
