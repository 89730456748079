<template>
  <ul class="about-page__list about-page__list-techs">
    <li>
      <img
        src="../../../imgs/icons/techs/html.webp"
        alt="HTML icon"
        loading="lazy"
      />
      <p>HTML</p>
    </li>
    <li>
      <img
        src="../../../imgs/icons/techs/css.webp"
        alt="CSS icon"
        loading="lazy"
      />
      <p>CSS</p>
    </li>
    <li>
      <img
        src="../../../imgs/icons/techs/sass.webp"
        alt="SASS icon"
        loading="lazy"
      />
      <p>SASS</p>
    </li>
    <li>
      <img
        src="../../../imgs/icons/techs/bootstrap.webp"
        alt="Bootstrap icon"
        loading="lazy"
      />
      <p>Bootstrap</p>
    </li>
    <li>
      <img
        src="../../../imgs/icons/techs/javascript.webp"
        alt="JavaScript icon"
        loading="lazy"
      />
      <p>JavaScript</p>
    </li>
    <li>
      <img
        src="../../../imgs/icons/techs/vuejs.webp"
        alt="VueJS icon"
        loading="lazy"
      />
      <p>VueJS</p>
    </li>
    <li>
      <img
        src="../../../imgs/icons/techs/react.webp"
        alt="ReactJS icon"
        loading="lazy"
      />
      <p>ReactJS</p>
    </li>
    <li>
      <img
        src="../../../imgs/icons/techs/nodejs.webp"
        alt="NodeJS icon"
        loading="lazy"
      />
      <p>NodeJS</p>
    </li>
    <li>
      <img
        src="../../../imgs/icons/techs/wordpress.webp"
        alt="WordPress icon"
        loading="lazy"
      />
      <p>WordPress</p>
    </li>
    <li>
      <img
        src="../../../imgs/icons/techs/php.webp"
        alt="PHP icon"
        loading="lazy"
      />
      <p>PHP</p>
    </li>
  </ul>
</template>
