<template>
  <div class="about-page__img-container">
    <div class="about-page__img">
      <img
        src="../../imgs/about-page-photo.webp"
        alt="Moje zdjęcie w czasie Urbexu"
        loading="lazy"
      />
    </div>
  </div>
</template>

<style scoped>
.about-page__img img {
  position: relative;
  width: 40rem;
  height: auto;

  border-radius: 1rem;
  box-shadow: 0 0 1rem var(--base-dark);
}

.about-page__img {
  position: relative;
  border-radius: 1rem;
}

.about-page__img::before {
  position: absolute;
  width: 102%;
  height: 102%;
  display: inline-block;
  content: "";
  background-color: var(--base-yellow);
  bottom: -3rem;
  left: -2rem;
  border-radius: 1rem;
  transition: 0.3s ease;

  box-shadow: 0 0 0.2rem var(--base-yellow);
}

.about-page__img::after {
  position: absolute;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  content: "o mnie";
  font-size: 10rem;
  text-align: center;
  color: var(--base-yellow);
  font-weight: 200;
  font-family: inherit;
  top: 0;
  left: 0;
  border-radius: 1rem;
  transition: 0.3s ease;

  background-color: #0c0f15c2;

  opacity: 0;
}

.about-page__img-container:hover .about-page__img::before {
  bottom: -2rem;
  left: -1rem;
}

.about-page__img-container:hover .about-page__img::after {
  opacity: 1;
}

/* 650px */
@media only screen and (max-width: 40.625em) {
  .about-page__img img {
    width: 30rem;
  }

  .about-page__img::after {
    font-size: 6rem;
  }
}

/* 450px */
@media only screen and (max-width: 28.125em) {
  .about-page__img img {
    width: 23rem;
  }

  .about-page__img::after {
    font-size: 5rem;
  }
}
</style>
