<template>
  <transition name="fade" mode="out-in">
    <div class="emailSent" v-if="emailSent" key="modal">
      <h2 v-if="emailSentSuccess">E-mail został wysłany poprawnie :)</h2>
      <h2 v-else>Napotkano problem... spróbuj ponownie :(</h2>
    </div>
    <form
      key="form"
      v-else
      @submit.prevent="sendEmail"
      class="contact-page__form"
      autocomplete="off"
    >
      <div class="contact-page__form-form">
        <div>
          <input
            type="text"
            name="name"
            id="name"
            placeholder="Imię i nazwisko"
            :class="{ error: !name.isValid }"
            v-model="name.inputValue"
            @input="checkName"
          />
          <input
            type="text"
            name="email"
            id="email"
            placeholder="Adres e-mail"
            :class="{ error: !mail.isValid }"
            v-model="mail.inputValue"
            @input="checkEmail"
          />
          <input
            type="number"
            name="tel"
            id="tel"
            placeholder="Telefon kontaktowy"
            :class="{ error: !tel.isValid }"
            v-model="tel.inputValue"
            @input="checkTel"
          />
          <textarea
            name="message"
            id="message"
            cols="30"
            rows="10"
            placeholder="Co mogę dla Ciebie zrobić?"
            :class="{ error: !message.isValid }"
            v-model="message.inputValue"
            @input="checkMessage"
          ></textarea>
        </div>

        <base-button mode="outline-dark">Wyślij</base-button>
      </div>
      <div class="contact-page__form-desc">
        <h2>Jesteś zainteresowany współpracą?</h2>
        <h2>Napisz do mnie!</h2>
        <img src="../../imgs/icons/message.webp" alt="" loading="lazy" />
        <h4>Skontaktuje się z Tobą w ciągu 24h od złożenia zapytania</h4>
      </div>
    </form>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      name: {
        inputValue: "",
        isValid: true,
      },
      mail: {
        inputValue: "",
        isValid: true,
      },
      tel: {
        inputValue: "",
        isValid: true,
      },
      message: {
        inputValue: "",
        isValid: true,
      },
      emailSent: false,
      emailSentSuccess: false,
    };
  },
  computed: {
    isFormValid() {
      this.checkName();
      this.checkEmail();
      this.checkTel();
      this.checkMessage();

      if (
        this.name.isValid &&
        this.mail.isValid &&
        this.tel.isValid &&
        this.message.isValid
      )
        return true;
      return false;
    },
  },
  methods: {
    checkName() {
      if (this.name.inputValue.trim().length === 0) this.name.isValid = false;
      else this.name.isValid = true;
    },
    checkEmail() {
      if (
        this.mail.inputValue.trim().length === 0 ||
        !this.mail.inputValue.includes("@")
      )
        this.mail.isValid = false;
      else this.mail.isValid = true;
    },
    checkTel() {
      if (String(this.tel.inputValue).trim().length < 9)
        this.tel.isValid = false;
      else this.tel.isValid = true;
    },
    checkMessage() {
      if (this.message.inputValue.trim().length === 0)
        this.message.isValid = false;
      else this.message.isValid = true;
    },
    async sendEmail() {
      if (this.isFormValid) {
        const data = {
          name: this.name.inputValue,
          mail: this.mail.inputValue,
          tel: this.tel.inputValue,
          message: this.message.inputValue,
          date: new Date().toLocaleString("pl-PL", {
            day: "numeric",
            month: "long",
            year: "numeric",
          }),
          time: new Date().toLocaleString("pl-PL", {
            hour: "numeric",
            minute: "numeric",
          }),
          marked: false,
        };

        const wasSuccessful = await this.$store.dispatch(
          "contact/sendEmail",
          data
        );

        if (wasSuccessful) this.clearInputs();

        this.emailSent = true;
        this.emailSentSuccess = wasSuccessful;

        setTimeout(() => {
          this.emailSent = false;
        }, 4000);

        return;
      }
    },
    clearInputs() {
      this.name = { inputValue: "", isValid: true };
      this.mail = { inputValue: "", isValid: true };
      this.tel = { inputValue: "", isValid: true };
      this.message = { inputValue: "", isValid: true };
    },
  },
};
</script>

<style scoped>
.emailSent {
  text-align: center;
  padding: 5rem 0;

  width: 100%;
  height: 100%;
}

.emailSent h2 {
  font-size: 2.4rem;
  font-weight: 600;
  color: var(--base-dark);
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: 0.3s ease;
}

.fade-enter-to {
  opacity: 1;
}

.contact-page__form {
  display: flex;
  align-items: center;
  gap: 4rem;
}

.contact-page__form-desc {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.contact-page__form-desc h2 {
  font-size: 3.6rem;
}

.contact-page__form-desc h2:first-of-type {
  font-size: 3rem;
  font-weight: 500;
}

.contact-page__form-desc h4 {
  margin-top: 4rem;
  font-size: 1.8rem;
  font-weight: 300;
  color: var(--base-dark-light-3);
}

.contact-page__form-desc img {
  width: 100px;
  height: 100px;
}

.contact-page__form-form {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  width: 60%;
}

.contact-page__form-form div {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.contact-page__form-form div input::placeholder,
.contact-page__form-form div textarea::placeholder {
  font-family: "Inter Tight", sans-serif;
  font-size: 1.6rem;
  color: var(--base-dark);
}

.contact-page__form-form div input,
.contact-page__form div textarea {
  font-family: inherit;
  border: none;
  outline: none;
  background-color: #a2a2a21e;
  padding: 1rem;
  border-bottom: 2px solid var(--base-dark);
  font-size: 1.6rem;

  transition: 0.2s ease;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

textarea {
  resize: vertical;
}

.error {
  border-bottom: 2px solid red !important;
}

.error,
.error::placeholder {
  color: red !important;
}

/* 900px */
@media only screen and (max-width: 56.25em) {
  .contact-page__form-desc h2 {
    font-size: 3rem;
  }

  .contact-page__form-desc h2:first-of-type {
    font-size: 2.4rem;
  }

  .contact-page__form-desc h4 {
    font-size: 1.6rem;
  }

  .contact-page__form-desc img {
    width: 70px;
    height: 70px;
  }
}

/* 750px */
@media only screen and (max-width: 46.875em) {
  .contact-page__form {
    flex-direction: column-reverse;
    gap: 8rem;
  }

  .contact-page__form-desc {
    align-items: center;
    text-align: center;
  }

  .contact-page__form-form {
    width: 100%;
  }
}
</style>
