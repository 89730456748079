<template>
  <section class="blog-page__section">
    <base-container>
      <div class="blog-page__container">
        <div class="blog-page__posts">
          <h2 v-if="posts.length === 0" class="blog-page__none">
            Aktualnie brak jakichkolwiek postów :(
          </h2>
          <base-spinner v-if="isLoading"></base-spinner>

          <blog-page-post
            v-else
            v-for="post in posts"
            :key="post.id"
            :postData="post"
          ></blog-page-post>
        </div>
        <blog-page-menu @active-filter="changeActiveFilters"></blog-page-menu>
      </div>
    </base-container>
  </section>
</template>

<script>
import BlogPagePost from "./BlogPagePost.vue";
import BlogPageMenu from "./BlogPageMenu.vue";

export default {
  components: {
    "blog-page-post": BlogPagePost,
    "blog-page-menu": BlogPageMenu,
  },
  emits: ["activeFilter"],
  data() {
    return {
      err: null,
      isLoading: false,
      activeFilters: {
        all: true,
        urbex: false,
        travel: false,
        web: false,
        work: false,
      },
    };
  },
  computed: {
    posts() {
      const posts = this.$store.getters["posts/getPosts"];

      return posts.filter((post) => {
        if (this.activeFilters.all) {
          return true;
        }
        if (this.activeFilters.urbex && post.tags.includes("urbex")) {
          return true;
        }
        if (this.activeFilters.travel && post.tags.includes("travel")) {
          return true;
        }
        if (this.activeFilters.web && post.tags.includes("web")) {
          return true;
        }
        if (this.activeFilters.work && post.tags.includes("work")) {
          return true;
        }
        return false;
      });
    },
  },
  methods: {
    changeActiveFilters(filters) {
      this.activeFilters = filters;
    },
  },

  async created() {
    this.isLoading = true;
    try {
      await this.$store.dispatch("posts/downloadPosts");
    } catch (err) {
      this.err = err;
    }
    this.isLoading = false;
  },
};
</script>

<style scoped>
.blog-page__none {
  font-size: 2rem;
  color: var(--base-yellow);
  font-weight: 500;
  text-align: center;
}

.blog-page__section {
  background-color: var(--base-dark-light-3);
  padding: 20rem 0;
  margin-bottom: -10rem;
  min-height: 90vh;
}

.blog-page__container {
  display: flex;
  gap: 8rem;
}

.blog-page__posts {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  min-width: 70%;
}

/* 1000px */
@media only screen and (max-width: 62.5em) {
  .blog-page__container {
    flex-direction: column-reverse;
    gap: 6rem;
  }

  .blog-page__posts {
    max-width: 100%;
  }
}

/* 600px */
@media only screen and (max-width: 37.5em) {
  .blog-page__section {
    padding: 15rem 0 20rem 0;
  }

  .blog-page__container {
    gap: 10rem;
  }
}
</style>
