<template>
  <section class="portfolio-page__section">
    <base-container>
      <div class="portfolio-page__header">
        <h1>Zrealizowane projekty</h1>
        <p>Odryj projekty, nad którymi pracowałem do tej pory</p>
      </div>

      <div class="portfolio-page__container">
        <portfolio-page-item
          v-for="project in projects"
          :key="project.id"
          :title="project.title"
          :src="project.src"
          :desc="project.desc"
          :techs="project.techs"
          :live="project.liveLink"
          :code="project.codeLink"
        ></portfolio-page-item>
      </div>
    </base-container>
  </section>
</template>

<script>
import PortfolioPageItem from "./PortfolioPageItem.vue";

export default {
  components: { "portfolio-page-item": PortfolioPageItem },
  data() {
    return {
      projects: [],
    };
  },
  created() {
    this.projects = this.$store.getters["portfolio/getPortfolioProjects"];
  },
};
</script>

<style scoped>
.portfolio-page__header {
  text-align: center;
  margin-bottom: 8rem;
}

.portfolio-page__header h1 {
  font-size: 3.6rem;
  font-weight: 600;
  letter-spacing: 2rem;

  text-transform: uppercase;
  background-image: linear-gradient(
    to right,
    var(--base-yellow),
    var(--base-yellow)
  );

  background-clip: text;
  -webkit-text-stroke-color: transparent;
  -webkit-text-stroke-width: 2px;
  -webkit-background-clip: text;
  color: #1b1e24;
}

.portfolio-page__header p {
  font-size: 1.8rem;
  color: #fff;
}

.portfolio-page__section {
  padding: 20rem 0;
  background-color: var(--base-dark);
  margin-bottom: -10rem;
}

.portfolio-page__container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  gap: 3rem;
}

/* 600px */
@media only screen and (max-width: 37.5em) {
  .portfolio-page__header h1 {
    font-size: 3rem;
    letter-spacing: 1rem;
  }

  .portfolio-page__section {
    padding: 15rem 0 20rem 0;
  }
}

/* 420px */
@media only screen and (max-width: 26.25em) {
  .portfolio-page__header h1 {
    font-size: 2.4rem;
  }
}
</style>
