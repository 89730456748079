export default {
  setPosts(state, payload) {
    state.posts = payload;
  },
  setThumbnailURL(state, payload) {
    const post = state.posts.find((post) => post.id === payload.id);

    post.tnURL = payload.tnURL;
  },
};
