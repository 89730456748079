<template>
  <div class="blog-page__menu">
    <h1>
      Dzielmy się razem <br /><span>doświadczeniami</span><br />
      <span>historiami</span><br />
      <span>wiedzą</span>
    </h1>
    <div class="blog-page__menu-seperator"></div>
    <div class="blog-page__menu-list-container">
      <h4>Tematy</h4>
      <ul class="blog-page__menu-list">
        <li>
          <button :class="{ active: filters.all }" @click="activeFilter('all')">
            Wszystkie posty
          </button>
        </li>
        <li>
          <button
            :class="{ active: filters.urbex }"
            @click="activeFilter('urbex')"
          >
            Urbex
          </button>
        </li>
        <li>
          <button
            :class="{ active: filters.travel }"
            @click="activeFilter('travel')"
          >
            Podróże
          </button>
        </li>
        <li>
          <button :class="{ active: filters.web }" @click="activeFilter('web')">
            Web
          </button>
        </li>
        <li>
          <button
            :class="{ active: filters.work }"
            @click="activeFilter('work')"
          >
            Praca
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      filters: {
        all: true,
        urbex: false,
        travel: false,
        web: false,
        work: false,
      },
    };
  },
  methods: {
    activeFilter(filter) {
      if (filter !== "all") this.filters.all = false;
      else {
        for (const key in this.filters) {
          if (this.filters[key] === "all") continue;
          this.filters[key] = false;
        }
      }

      if (!this.filters[filter]) {
        this.filters[filter] = true;
      } else {
        this.filters[filter] = false;
      }

      this.$emit("activeFilter", this.filters);
    },
  },
};
</script>

<style scoped>
.active {
  background-color: var(--base-yellow) !important;
  color: var(--base-dark) !important;
}

.blog-page__menu {
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 4rem;
}

.blog-page__menu h1 {
  font-size: 3rem;
  color: var(--base-yellow);
  font-weight: 600;
}

.blog-page__menu h1 span {
  font-weight: 500;
  font-size: 2.5rem;
  color: #fff;
}

.blog-page__menu-seperator {
  width: 50%;
  display: inline-block;
  height: 0.2rem;
  background-color: var(--base-yellow);
}

.blog-page__menu-list-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.blog-page__menu-list-container h4 {
  color: #fff;
  font-size: 1.8rem;
  letter-spacing: 0.5rem;
}

.blog-page__menu-list {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}

.blog-page__menu-list li button {
  cursor: pointer;
  background: none;
  border: none;
  color: var(--base-yellow-light-1);
  border: 1px solid var(--base-yellow-light-1);
  font-size: 1.8rem;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  box-shadow: 0 0 0.8rem var(--base-dark-light-2);

  transition: 0.3s ease;
}

.blog-page__menu-list li button:hover {
  color: var(--base-yellow);
  border-color: var(--base-yellow);
  box-shadow: 0 0 1rem var(--base-dark);
}

/* 1000px */
@media only screen and (max-width: 62.5em) {
  .blog-page__menu {
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .blog-page__menu h1 {
    text-align: center;
    border-right: 2px solid var(--base-yellow);
    padding-right: 6rem;
  }

  .blog-page__menu-seperator {
    display: none;
  }

  .blog-page__menu-list-container {
    align-items: center;
  }
}

/* 600px */
@media only screen and (max-width: 37.5em) {
  .blog-page__menu {
    flex-direction: column;
  }

  .blog-page__menu h1 {
    border-right: none;
    padding-right: 0;
  }

  .blog-page__menu-seperator {
    display: inline-block;
  }
}

/* 400px */
@media only screen and (max-width: 25em) {
  .blog-page__menu-list {
    justify-content: center;
  }
}
</style>
