<template>
  <section class="contact-page__section">
    <base-container>
      <contact-page-info></contact-page-info>
      <div class="contact-page__container">
        <contact-page-form></contact-page-form>
      </div>
    </base-container>
  </section>
</template>

<script>
import ContactPageInfo from "./ContactPageInfo.vue";
import ContactPageForm from "./ContactPageForm.vue";

export default {
  components: {
    "contact-page-info": ContactPageInfo,
    "contact-page-form": ContactPageForm,
  },
};
</script>

<style scoped>
.contact-page__section {
  margin-top: 25rem;
}

.contact-page__container {
  margin-top: 10rem;
  padding: 4rem;
  background-color: var(--base-yellow);
  box-shadow: 0 0 4rem var(--base-dark-light-1);
  border-radius: 2rem;
}

/* 900px */
@media only screen and (max-width: 56.25em) {
  .contact-page__section {
    margin-top: 20rem;
  }
}

/* 750px */
@media only screen and (max-width: 46.875em) {
  .contact-page__container {
    padding: 4rem 2rem;
  }
}

/* 630px */
@media only screen and (max-width: 39.375em) {
  .contact-page__section {
    margin-top: 17rem;
  }
}
</style>
