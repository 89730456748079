export default {
  async sendDataToRDB(_, payload) {
    try {
      const token = localStorage.getItem("userToken");

      const res = await fetch(
        `https://goatweb-blog-default-rtdb.europe-west1.firebasedatabase.app/Posts.json?auth=${token}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (!res.ok) throw new Error("nie udało się zapisać danych w RDB");

      const { name: postID } = await res.json();

      return postID;
    } catch (e) {
      const err = `Błąd: ${e}`;
      throw err;
    }
  },
  async downloadPosts(context) {
    const res = await fetch(
      "https://goatweb-blog-default-rtdb.europe-west1.firebasedatabase.app/Posts.json"
    );

    if (!res.ok) throw new Error("nie udało się pobrać postów");

    const response = await res.json();

    const posts = [];

    for (const key in response) {
      const post = {
        id: key,
        date: response[key].date,
        title: response[key].title,
        desc: response[key].desc,
        tags: response[key].tags,
        thumbnailURL: `${key}/thumbnail.webp`,
        imgsURL: `${key}/imgs/`,
      };

      posts.push(post);
    }

    context.commit("setPosts", posts);
  },
};
