<template>
  <ul class="about-page__list about-page__list-conventions">
    <li>
      <img
        src="../../../imgs/icons/techs/sync.webp"
        alt="AJAX icon"
        loading="lazy"
      />
      <p>AJAX</p>
    </li>
    <li>
      <img
        src="../../../imgs/icons/techs/json.webp"
        alt="JSON icon"
        loading="lazy"
      />
      <p>JSON</p>
    </li>
    <li>
      <img
        src="../../../imgs/icons/techs/rest.webp"
        alt="REST icon"
        loading="lazy"
      />
      <p>REST</p>
    </li>
    <li>
      <img
        src="../../../imgs/icons/techs/responsive.webp"
        alt="RWD icon"
        loading="lazy"
      />
      <p>RWD</p>
    </li>
    <li>
      <img
        src="../../../imgs/icons/techs/model.webp"
        alt="MVC icon"
        loading="lazy"
      />
      <p>MVC</p>
    </li>
    <li>
      <img
        src="../../../imgs/icons/techs/seo.webp"
        alt="SEO icon"
        loading="lazy"
      />
      <p>SEO</p>
    </li>
  </ul>
</template>
