<template>
  <li
    :class="{
      'pos-right': ifRight,
      'gradient-left-dark': !ifRight,
      'gradient-right-dark': ifRight,
    }"
  >
    <div>
      <img
        :src="require(`../../../imgs/icons/${iconName}.webp`)"
        :alt="`${iconName} icon`"
      />
      <h3>{{ title }}</h3>
    </div>
    <p>{{ desc }}</p>
  </li>
</template>

<script>
export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
    iconName: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    desc: {
      type: String,
      required: true,
    },
  },

  computed: {
    ifRight() {
      return (this.id + 1) % 2 === 0 ? true : false;
    },
  },
};
</script>

<style scoped>
li {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10rem;
  padding: 2rem;
  box-shadow: 1rem 1rem 2rem var(--base-dark-light-2);

  gap: 8rem;

  transition: 0.3s ease;
}

li:hover {
  transform: scale(1.02);
  box-shadow: 1rem 1rem 1rem var(--base-dark-light-2);
}

li:hover div img {
  filter: drop-shadow(0 0 0.2rem var(--base-yellow));
}

li:hover div h3 {
  filter: drop-shadow(0 0 0.2rem var(--base-yellow));
}

li div {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

li p {
  width: 40%;
  font-size: 1.8rem;
  color: #fff;
}

li div img {
  width: 50px;
  height: 50px;
  transition: 0.3s ease;
}

li div h3 {
  font-size: 3rem;
  color: var(--base-yellow);
  transition: 0.3s ease;
  font-weight: 300;
}

.pos-right {
  flex-direction: row-reverse;
}

.gradient-left-dark {
  background: linear-gradient(to right, rgba(0, 0, 0, 0.582), #0000003a);
}

.gradient-right-dark {
  background: linear-gradient(to left, rgba(0, 0, 0, 0.582), #0000003a);
}

/* 1000px*/
@media only screen and (max-width: 62.5em) {
  li,
  .pos-right {
    flex-direction: column;
    gap: 2rem;
  }

  li p {
    width: 80%;
    font-size: 1.8rem;
  }
}

/* 700px */
@media only screen and (max-width: 43.75em) {
  li p {
    font-size: 1.6rem;
    text-align: justify;
    line-height: 2.2rem;
  }
}

/* 550px */
@media only screen and (max-width: 34.375em) {
  li p {
    width: 90%;
    padding-bottom: 4rem;
  }
}

/* 450px */
@media only screen and (max-width: 28.125em) {
  li div h3 {
    font-size: 2.8rem;
    text-align: center;
  }

  li div img {
    width: 40px;
    height: 40px;
    transition: 0.3s ease;
  }
}
</style>
