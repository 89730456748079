<template>
  <section class="home-page__section">
    <div class="dark-layout"></div>
    <base-container>
      <base-section-title class="home-page__section-title"
        >Strony WWW</base-section-title
      >
      <h1>
        Projektowanie & <br />Tworzenie <br /><span>stron internetowych</span>
      </h1>
      <p>
        Jestem profesjonalistą zajmującym się projektowaniem i tworzeniem
        aplikacji internetowych, sklepów internetowych oraz aplikacji webowych.
        Specjalizuje się również w optymalizacji SEO i wyróżnianiu stron w
        Google - również wizytówek Google. Moim celem jest pomaganie klientom w
        odniesieniu sukcesu online.<br /><br />
        Skontaktuj się ze mną już teraz, abym mógł dostarczyć Ci zaawansowane
        rozwiązania internetowe i przyczynić się do Twojego sukcesu w sieci.
      </p>

      <div class="home-page__section-btn">
        <base-button mode="outline" radius="right" link="kontakt"
          >Skontaktuj się</base-button
        >
      </div>
    </base-container>
  </section>
</template>

<style scoped>
.home-page__section {
  position: relative;
  background-color: var(--base-dark);
  background-image: url("../../imgs/header-section-bg.webp");
  background-size: cover;
  height: 80rem;
  padding-top: 20rem;
}

.dark-layout {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  display: inline-block;
  background-color: #0c0f15ef;
}

.home-page__section h1 {
  color: var(--base-yellow);
  font-weight: 600;
  font-size: 6rem;

  line-height: 6rem;
}

h1 span {
  animation: blink 4s infinite;
  letter-spacing: 1rem;
}

@keyframes blink {
  0% {
    filter: drop-shadow(0 0 0 black);
  }

  50% {
    filter: drop-shadow(0 0 0.5rem #ffd900b6);
  }

  100% {
    filter: drop-shadow(0 0 0 black);
  }
}

.home-page__section p {
  font-weight: 500;
  width: 60%;
  color: #fff;
  font-size: 1.8rem;
  margin-top: 3rem;
  line-height: 2.5rem;

  border-left: 3px solid var(--base-yellow);
  padding-left: 2rem;
}

.home-page__section-btn {
  margin-top: 7rem;
}

.home-page__section button {
  border-top-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.home-page__section button:hover {
  box-shadow: 0 0 0.5rem var(--base-yellow);
}

/* 1100px */
@media only screen and (max-width: 67.75em) {
  .home-page__section {
    height: auto;
    padding-bottom: 10rem;
  }
}

/* 1000px */
@media only screen and (max-width: 62.5em) {
  .home-page__section {
    background-position-x: 30%;
  }
}

/* 900px */
@media only screen and (max-width: 56.25em) {
  .home-page__section {
    padding-top: 5rem;
  }
}

/* 800px */
@media only screen and (max-width: 50em) {
  .home-page__section h1 {
    font-size: 4.6rem;
    line-height: 4rem;
  }

  .home-page__section p {
    width: 90%;
    font-size: 1.6rem;
  }
}

/* 600px */
@media only screen and (max-width: 37.5em) {
  .home-page__section {
    background-position-x: 45%;
  }
}

/* 500px */
@media only screen and (max-width: 31.25em) {
  .home-page__section {
    padding-top: 15rem;
  }

  .home-page__section-title {
    text-align: center;
  }

  .home-page__section h1 {
    text-align: center;
    font-size: 4.2rem;
    line-height: 3.6rem;
  }

  .home-page__section p {
    font-size: 1.6rem;
    line-height: 2rem;
  }

  .home-page__section-btn a:link,
  .home-page__section-btn:visited {
    font-size: 1.6rem;
  }

  .home-page__section-btn {
    display: grid;
    place-items: center;
  }
}

/* 460px */
@media only screen and (max-width: 28.75em) {
  .home-page__section h1 {
    font-size: 3.2rem;
    line-height: 3.2rem;
  }

  .home-page__section p {
    width: 95%;
  }
}

/* 380px */
@media only screen and (max-width: 23.75em) {
  .home-page__section h1 {
    font-size: 2.6rem;
    line-height: 3rem;
  }

  h1 span {
    animation: blink 4s infinite;
    letter-spacing: 0.6rem;
  }
}
</style>
