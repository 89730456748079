import { createRouter, createWebHistory } from "vue-router";
import HomePage from "./pages/HomePage";
import OffersPage from "./pages/OffersPage";
import AboutPage from "./pages/AboutPage/AboutPage";
import PortfolioPage from "./pages/PortfolioPage/PortfolioPage";
import BlogPage from "./pages/BlogPage/BlogPage";
import PostDetails from "./pages/BlogPage/PostDetails";
import ContactPage from "./pages/ContactPage/ContactPage";
import LoginPage from "./pages/auth/LoginPage";
import WebsiteEditorPage from "./pages/auth/WebsiteEditorPage/WebsiteEditorPage";
import store from "./store/index";
import NotFound from "./pages/NotFound";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      component: HomePage,
      meta: {
        title:
          "GoatWeb Solutions - projektowanie i tworzenie rozwiązań webowych",
        metaTags: [
          {
            name: "description",
            content:
              "Jestem profesjonalistą zajmującym się projektowaniem i tworzeniem aplikacji internetowych, sklepów internetowych oraz aplikacji webowych. Specjalizuje się również w optymalizacji SEO i wyróżnianiu stron w Google - również wizytówek Google.",
          },
          {
            property: "og:description",
            content:
              "Jestem profesjonalistą zajmującym się projektowaniem i tworzeniem aplikacji internetowych, sklepów internetowych oraz aplikacji webowych. Specjalizuje się również w optymalizacji SEO i wyróżnianiu stron w Google - również wizytówek Google.",
          },
          {
            name: "keywords",
            content:
              "GoatWeb Solutions; Projektowanie i tworzenie stron internetowych; Projektowanie i tworzenie sklepów internetowych; Projektowanie i tworzenie aplikacji webowych;",
          },
          {
            property: "og:keywords",
            content:
              "GoatWeb Solutions; Projektowanie i tworzenie stron internetowych; Projektowanie i tworzenie sklepów internetowych; Projektowanie i tworzenie aplikacji webowych;",
          },
        ],
      },
    },
    {
      path: "/uslugi",
      component: OffersPage,
      meta: {
        title: "Usługi - GoatWeb Solutions",
        metaTags: [
          {
            name: "description",
            content:
              "Profesjonalne usługi tworzenia i projektowania responsywnych stron internetowych, które skutecznie reprezentują Twoją markę. Zwiększ swoją obecność online już dziś!",
          },
          {
            property: "og:description",
            content:
              "Profesjonalne usługi tworzenia i projektowania responsywnych stron internetowych, które skutecznie reprezentują Twoją markę. Zwiększ swoją obecność online już dziś!",
          },
          {
            name: "keywords",
            content:
              "tworzenie stron internetowych; projektowanie stron; responsywne witryny; indywidualne rozwiązania; strona internetowa dla firmy",
          },
          {
            property: "og:keywords",
            content:
              "tworzenie stron internetowych; projektowanie stron; responsywne witryny; indywidualne rozwiązania; strona internetowa dla firmy",
          },
        ],
      },
    },
    {
      path: "/o-mnie",
      component: AboutPage,
      meta: {
        title: "Poznaj mnie - Jakub Kozanecki",
        metaTags: [
          {
            name: "description",
            content:
              "Dowiedz się więcej o mnie - pasjonacie tworzenia stron internetowych. Zobacz, jak moje doświadczenie i kreatywność przekładają się na unikalne i efektywne projekty online",
          },
          {
            property: "og:description",
            content:
              "Dowiedz się więcej o mnie - pasjonacie tworzenia stron internetowych. Zobacz, jak moje doświadczenie i kreatywność przekładają się na unikalne i efektywne projekty online",
          },
          {
            name: "keywords",
            content:
              "o mnie; tworzenie stron internetowych; pasja; doświadczenie; kreatywność; projekty online",
          },
          {
            property: "og:keywords",
            content:
              "o mnie; tworzenie stron internetowych; pasja; doświadczenie; kreatywność; projekty online",
          },
        ],
      },
    },
    {
      path: "/portfolio",
      component: PortfolioPage,
      meta: {
        title: "Portfolio - GoatWeb Solutions",
        metaTags: [
          {
            name: "description",
            content:
              "Zapoznaj się z moim zróżnicowanym portfolio, prezentującym różnorodne projekty stron internetowych. Zobacz, jak kreuję unikalne i skuteczne rozwiązania online dla różnych branż.",
          },
          {
            property: "og:description",
            content:
              "Zapoznaj się z moim zróżnicowanym portfolio, prezentującym różnorodne projekty stron internetowych. Zobacz, jak kreuję unikalne i skuteczne rozwiązania online dla różnych branż. ",
          },
          {
            name: "keywords",
            content:
              "portfolio; projekty stron internetowych; różnorodność projektów; kreatywne rozwiązania",
          },
          {
            property: "og:keywords",
            content:
              "portfolio; projekty stron internetowych; różnorodność projektów; kreatywne rozwiązania",
          },
        ],
      },
    },
    {
      path: "/blog",
      component: BlogPage,
      meta: {
        title: "Blog - GoatWeb Solutions",
        metaTags: [
          {
            name: "description",
            content:
              "Przeczytaj mojego bloga, gdzie dzielę się moimi pasjami jak tworzenie stron internetowych czy urbex. Poszerz swoją wiedzę na temat moich hobby",
          },
          {
            property: "og:description",
            content:
              "Przeczytaj mojego bloga, gdzie dzielę się moimi pasjami jak tworzenie stron internetowych czy urbex. Poszerz swoją wiedzę na temat moich hobby",
          },
          {
            name: "keywords",
            content:
              "blog; wskazówki projektowania; trendy online; tworzenie stron internetowych; artykuły branżowe; urbex; opuszczone miejsca",
          },
          {
            property: "og:keywords",
            content:
              "blog; wskazówki projektowania; trendy online; tworzenie stron internetowych; artykuły branżowe; urbex; opuszczone miejsca",
          },
        ],
      },
    },
    {
      path: "/blog/:post",
      component: PostDetails,
      props: true,
      meta: {
        title: "Blog - GoatWeb Solutions",
        metaTags: [
          {
            name: "description",
            content:
              "Przeczytaj mojego bloga, gdzie dzielę się moimi pasjami jak tworzenie stron internetowych czy urbex. Poszerz swoją wiedzę na temat moich hobby",
          },
          {
            property: "og:description",
            content:
              "Przeczytaj mojego bloga, gdzie dzielę się moimi pasjami jak tworzenie stron internetowych czy urbex. Poszerz swoją wiedzę na temat moich hobby",
          },
          {
            name: "keywords",
            content:
              "blog; wskazówki projektowania; trendy online; tworzenie stron internetowych; artykuły branżowe; urbex; opuszczone miejsca",
          },
          {
            property: "og:keywords",
            content:
              "blog; wskazówki projektowania; trendy online; tworzenie stron internetowych; artykuły branżowe; urbex; opuszczone miejsca",
          },
        ],
      },
    },
    {
      path: "/kontakt",
      component: ContactPage,
      meta: {
        title: "Kontakt - GoatWeb Solutions",
        metaTags: [
          {
            name: "description",
            content:
              "Skontaktuj się ze mną, aby omówić swoje potrzeby związane z tworzeniem stron internetowych. Czekam na Twój telefon lub wiadomość e-mail, abyśmy mogli zacząć pracować nad Twoim projektem online",
          },
          {
            property: "og:description",
            content:
              "Zapoznaj się z moim zróSkontaktuj się ze mną, aby omówić swoje potrzeby związane z tworzeniem stron internetowych. Czekam na Twój telefon lub wiadomość e-mail, abyśmy mogli zacząć pracować nad Twoim projektem online",
          },
          {
            name: "keywords",
            content:
              "kontakt;skontaktuj się;tworzenie stron internetowych;współpraca;omówienie projektu",
          },
          {
            property: "og:keywords",
            content:
              "kontakt;skontaktuj się;tworzenie stron internetowych;współpraca;omówienie projektu",
          },
        ],
      },
    },
    {
      path: "/login",
      component: LoginPage,
      meta: { requiresUnAuth: true, title: "Logowanie - GoatWeb Solutions" },
    },
    {
      path: "/website-editor",
      component: WebsiteEditorPage,
      meta: { requiresAuth: true, title: "GoatWeb Editor" },
    },
    {
      path: "/:notFound(.*)",
      component: NotFound,
      meta: {
        title: "Nie znaleziono strony - GoatWeb Solutions",
        metaTags: [
          {
            name: "description",
            content:
              "Jestem profesjonalistą zajmującym się projektowaniem i tworzeniem aplikacji internetowych, sklepów internetowych oraz aplikacji webowych. Specjalizuje się również w optymalizacji SEO i wyróżnianiu stron w Google - również wizytówek Google.",
          },
          {
            property: "og:description",
            content:
              "Jestem profesjonalistą zajmującym się projektowaniem i tworzeniem aplikacji internetowych, sklepów internetowych oraz aplikacji webowych. Specjalizuje się również w optymalizacji SEO i wyróżnianiu stron w Google - również wizytówek Google.",
          },
          {
            name: "keywords",
            content:
              "GoatWeb Solutions; Projektowanie i tworzenie stron internetowych; Projektowanie i tworzenie sklepów internetowych; Projektowanie i tworzenie aplikacji webowych;",
          },
          {
            property: "og:keywords",
            content:
              "GoatWeb Solutions; Projektowanie i tworzenie stron internetowych; Projektowanie i tworzenie sklepów internetowych; Projektowanie i tworzenie aplikacji webowych;",
          },
        ],
      },
    },
  ],
  scrollBehavior(_1, _2, savedPosition) {
    if (savedPosition) return savedPosition;
    return { top: 0 };
  },
});

router.beforeEach((to, _, next) => {
  if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
    next("/login");
  } else if (to.meta.requiresUnAuth && store.getters.isAuthenticated) {
    next("/website-editor");
  } else {
    next(true);
  }
});

router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el.parentNode.removeChild(el)
  );

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));

  next();
});

export default router;
