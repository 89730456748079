import getters from "./getters";

export default {
  namespaced: true,
  state() {
    return {
      portfolioProjects: [
        {
          id: 0,
          title: "V&T Delivery App",
          desc: "Aplikacja stworzona dla Visit & Taste. Aplikacja pozwala na zamówienie jedzenia oraz wybrania sposobu dostawy, bądź odbioru. W fazie rozwoju, oczekuje na wybór platformy płatnościowej i na optymalizacje",
          techs: ["HTML", "CSS", "React"],
          src: "vat",
          liveLink: "https://onlinedelivery-visitandtaste.netlify.app",
          codeLink: "false",
        },
        {
          id: 1,
          title: "ZS Trzemeszno",
          desc: "Prosta strona stworzona dla szkoły ZS Trzemeszno. Wykorzystano WordPress'a, motyw oparty na pluginie ACF Pro w celu łatwiejszego zarządzania treścią przez administratora",
          techs: ["HTML", "CSS", "Bootstrap", "JavaScript"],
          src: "zst",
          liveLink: "https://zstrzemeszno.pl/",
          codeLink: "false",
        },
        {
          id: 2,
          title: "Giard Design",
          desc: "Strona stworzona dla fikcyjnej firmy na poczet rekrutacji AdRespect. Wykorzystano w tym projekcie Bootstrap'a oraz czysty kod CSS, JS",
          techs: ["HTML", "CSS", "Bootstrap", "JavaScript"],
          src: "giard",
          liveLink: "https://giardd.netlify.app/",
          codeLink: "https://github.com/j4kk0b/GiardDesign",
        },
        {
          id: 3,
          title: "GoatWeb Solutions",
          desc: "Strona, na której się znajdujesz została stworzona przeze mnie. Wyposażona jest w autorski system 'GoatWeb Editor'. Wszystko co znajduje się na stronie zostało napisane we frameworku VueJS - blog i pocztę zarządza się przez stronę, wymagane jest zalogowanie",
          techs: ["HTML", "CSS", "JavaScript", "VueJS"],
          src: "goatweb",
          liveLink: "false",
          codeLink: "false",
        },
        {
          id: 4,
          title: "ANS Gniezno",
          desc: "Strona internetowa stworzona dla ANS w Gnieźnie. Wykorzystano w niej WordPressa dla łatwiejszego administrowania przez użytkownika. Zaimplementowano również przyciski WCAG2.1, aby strona była bardziej dostępna",
          techs: ["HTML", "CSS", "WordPress", "PHP"],
          src: "ans",
          liveLink: "https://ans-gniezno.edu.pl/",
          codeLink: "false",
        },
        {
          id: 5,
          title: "Forkify",
          desc: "Strona stworzona dla osób lubiących gotować i poznawać smaki świata. Użytkownik może wyszukać przepis, wybrać liczbę porcji, zapisać przepis i dodać własne przepisy",
          techs: ["HTML", "CSS", "MVC", "API"],
          src: "forkify",
          liveLink: "https://jacob-forkify-project.netlify.app/",
          codeLink: "https://github.com/j4kk0b/Forkify",
        },
        {
          id: 6,
          title: "Bankist",
          desc: "Aplikacja umożliwia przesyłanie kwot między użytkownikami, zaciąganie pożyczek lub zamykanie konta. Dostępne są dwa przykładowe konta - aby przetestować wybierz opcję Testuj aplikację w nawigacji",
          techs: ["HTML", "CSS", "JavaScript"],
          src: "bankist",
          liveLink: "https://jacob-bankist-project.netlify.app/",
          codeLink: "https://github.com/j4kk0b/Bankist",
        },
        {
          id: 7,
          title: "Mapty",
          desc: "Aplikacja pozwala użytkownikowi na zapisywanie ostatnich biegów i przejażdżek rowerowych - a także statystyk",
          techs: ["HTML", "CSS", "JavaScript", "API"],
          src: "mapty",
          liveLink: "https://jacob-mapty-project.netlify.app/",
          codeLink: "https://github.com/j4kk0b/Mapty",
        },
        {
          id: 8,
          title: "Trillo",
          desc: "Prosta strona do rezerowania hoteli, wynajmowania aut oraz lotów",
          techs: ["HTML", "SASS", "NPM"],
          src: "trillo",
          liveLink: "https://jacob-trillo-project.netlify.app/",
          codeLink: "https://github.com/j4kk0b/Trillo",
        },
        {
          id: 9,
          title: "Tindog",
          desc: "Strona internetowa inspirowana Tinder'em... ale dla psów! Perfekcyjna dla posiadaczy psów, którzy chcą zadbać o swoje pupile",
          techs: ["HTML", "CSS", "Bootstrap"],
          src: "tindog",
          liveLink: "https://jacob-tindog-project.netlify.app/",
          codeLink: "https://github.com/j4kk0b/Tindog",
        },
        {
          id: 10,
          title: "Natours",
          desc: "Kochasz podróżować i nie boisz się nie znanego? To dobre miejsce dla Ciebie",
          techs: ["HTML", "SASS", "JavaScript"],
          src: "natours",
          liveLink: "https://jacob-natour-project.netlify.app/",
          codeLink: "https://github.com/j4kk0b/Natours",
        },
        {
          id: 11,
          title: "Nexter",
          desc: "Strona stworzona dla fikcyjnej firmy zajmującej się sprzedażą posiadłości",
          techs: ["HTML", "SASS"],
          src: "nexter",
          liveLink: "https://jacob-nexter-project.netlify.app/",
          codeLink: "https://github.com/j4kk0b/Nexter",
        },
        {
          id: 12,
          title: "Omnifood",
          desc: "Strona poglądowa dla fikcyjnego start-up'u Omnifood, gdzie AI przyrządza perfekcyjne dania dla użytkowników",
          techs: ["HTML", "CSS", "JavaScript"],
          src: "omnifood",
          liveLink: "https://jacob-omnifood-project.netlify.app/",
          codeLink: "https://github.com/j4kk0b/Omnifood",
        },
        {
          id: 13,
          title: "Simon Game",
          desc: "Gra posiada nieskończenie wiele poziomów, każda gra z unikalnym wzorem",
          techs: ["HTML", "CSS", "JavaScript"],
          src: "simon",
          liveLink: "https://jacob-simongame-project.netlify.app/",
          codeLink: "https://github.com/j4kk0b/Simon-Game",
        },
      ],
    };
  },
  getters,
};
