<template>
  <div class="base-width">
    <slot></slot>
  </div>
</template>

<style scoped>
.base-width {
  max-width: 118rem;
  margin: 0 auto;
  position: relative;
  padding: 0 2rem;
}
</style>
