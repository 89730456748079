export default {
  async sendEmail(_, payload) {
    const resCode = await fetch(
      `https://goatweb-blog-default-rtdb.europe-west1.firebasedatabase.app/Emails.json`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    )
      .then((res) => res.json())
      .then((info) => {
        if (!info) throw new Error("Error occured");
        return true;
      })
      .catch(() => false);

    return resCode;
  },
  async downloadEmails(context) {
    try {
      const token = localStorage.getItem("userToken");

      const resCode = await fetch(
        `https://goatweb-blog-default-rtdb.europe-west1.firebasedatabase.app/Emails.json?auth=${token}`
      );
      if (!resCode.ok) throw new Error("nie udało się pobrać maili");

      const res = await resCode.json();

      const allEmails = [];

      for (const key in res) {
        const email = {
          id: key,
          name: res[key].name,
          mail: res[key].mail,
          date: res[key].date,
          time: res[key].time,
          tel: res[key].tel,
          message: res[key].message,
          marked: res[key].marked,
        };

        allEmails.push(email);
      }

      allEmails.sort((a, b) => {
        if (a.date > b.date) return -1;
        if (a.date < b.date) return 1;

        if (a.time > b.time) return -1;
        if (a.time < b.time) return 1;

        return 0;
      });

      context.commit("setAllEmails", allEmails);
    } catch (err) {
      const e = `Wystąpił błąd: ${err.message}`;
      throw e;
    }
  },
  async markAs(_, payload) {
    try {
      const token = localStorage.getItem("userToken");

      const res = await fetch(
        `https://goatweb-blog-default-rtdb.europe-west1.firebasedatabase.app/Emails/${payload.mailID}.json?auth=${token}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            marked: payload.option,
          }),
        }
      );

      if (!res.ok) throw new Error("nie udało się wybrać wskazanego maila");
    } catch (err) {
      const e = `Wystąpił błąd: ${err.message}`;
      throw e;
    }
  },
};
