<template>
  <section class="offers__section">
    <base-container>
      <base-section-title mode="dark" class="offers__section-title"
        >Usługi</base-section-title
      >

      <div class="offers_list">
        <div>
          <img
            src="../../imgs/icons/website.webp"
            alt="Website icon"
            class="offers_list-icon"
            loading="lazy"
          />
          <p>Zaprojektowanie i stworzenie strony internetowej</p>
        </div>
        <div>
          <img
            src="../../imgs/icons/shop.webp"
            alt="Website icon"
            class="offers_list-icon"
            loading="lazy"
          />
          <p>Zaprojektowanie i stworzenie sklepu internetowego</p>
        </div>
        <div>
          <img
            src="../../imgs/icons/code.webp"
            alt="Website icon"
            class="offers_list-icon"
            loading="lazy"
          />
          <p>Zaprojektowanie i stworzenie aplikacji webowej</p>
        </div>
        <div>
          <img
            src="../../imgs/icons/lock.webp"
            alt="Website icon"
            class="offers_list-icon"
            loading="lazy"
          />
          <p>Hosting, domena, certyfikat SSL</p>
        </div>
        <div>
          <img
            src="../../imgs/icons/seo.webp"
            alt="Website icon"
            class="offers_list-icon"
            loading="lazy"
          />
          <p>Optymalizacja SEO, optymalizacja w wyszukiwarce</p>
        </div>
        <div>
          <img
            src="../../imgs/icons/business-card.webp"
            alt="Website icon"
            class="offers_list-icon"
            loading="lazy"
          />
          <p>Stworzenie wizytówki Google</p>
        </div>
      </div>

      <div class="offers__section-btn">
        <base-button mode="outline-dark" radius="left" link="uslugi"
          >Dowiedz się więcej &rarr;</base-button
        >
      </div>
    </base-container>
  </section>
</template>

<script>
export default {
  data() {
    return {
      projects: [],
    };
  },
};
</script>

<style scoped>
.offers__section {
  margin-top: 8rem;
}

.offers_list {
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
}

.offers_list div {
  width: 31%;
  height: 20rem;
  /* border: 2px solid var(--base-dark); */
  border-radius: 1rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  text-align: center;
  cursor: pointer;
  box-shadow: 0 0.2rem 1rem var(--base-dark-light-1);

  font-size: 1.8rem;

  transition: 0.3s ease;
}

.offers_list div:hover {
  background-color: var(--base-yellow);
  color: var(--base-dark);
  box-shadow: 0 0.2rem 1rem var(--base-dark-light-2);
  transform: scale(1.02);
}

.offers_list-icon {
  width: 50px;
  height: 50px;
}

.offers__section-btn {
  margin-top: 4rem;
  margin-right: 2rem;

  display: flex;
  justify-content: flex-end;
}

/* 1000px */
@media only screen and (max-width: 62.5em) {
  .offers_list {
    justify-content: center;
  }

  .offers_list div {
    width: 45%;
  }
}

/* 650px */
@media only screen and (max-width: 40.625em) {
  .offers_list-icon {
    width: 40px;
    height: 40px;
  }

  div p {
    font-size: 1.4rem;
  }
}

/* 500px */
@media only screen and (max-width: 31.25em) {
  .offers_list div {
    width: 90%;
  }

  .offers_list-icon {
    width: 50px;
    height: 50px;
  }

  div p {
    font-size: 1.8rem;
  }

  .offers__section-btn a:link,
  .offers__section-btn a:visited {
    font-size: 1.6rem;
  }
}

/* 380px */
@media only screen and (max-width: 23.75em) {
  .offers__section-title {
    text-align: center;
  }

  .offers_list div {
    width: 100%;
    padding: 0.2rem;
  }

  div p {
    font-size: 1.6rem;
  }

  .offers__section-btn {
    margin-top: 4rem;
    margin-right: 0;

    justify-content: center;
  }
}
</style>
