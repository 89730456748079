<template>
  <div class="section-footer__contact-container">
    <img
      class="section-footer__logo"
      src="../../imgs/logo-yellow.webp"
      alt="GoatWeb Solutions logo"
      loading="lazy"
    />
    <div class="section-footer__contact">
      <p>GoatWeb Solutions</p>
      <h3>Jakub Kozanecki</h3>
      <h4>Gniezno 62-200</h4>
      <h4>
        <a href="tel:692251700"
          ><img
            src="../../imgs/icons/call.webp"
            alt="Telephone Icon"
            loading="lazy"
          />
          (+48) 692 251 700</a
        >
      </h4>
      <h4>
        <a href="mailto:jakubkozaneckidev@gmail.com"
          ><img
            src="../../imgs/icons/mail.webp"
            alt="E-mail Icon"
            loading="lazy"
          />
          jakubkozaneckidev@gmail.com</a
        >
      </h4>

      <ul class="section-footer__socials">
        <li>
          <a href="https://github.com/j4kk0b"
            ><img
              src="../../imgs/icons/github.webp"
              alt="GitHub icon"
              loading="lazy"
          /></a>
        </li>
        <li>
          <a href="https://www.linkedin.com/in/jakub-kozanecki-66b151251/"
            ><img
              src="../../imgs/icons/linkedin.webp"
              alt="LinkedIn icon"
              loading="lazy"
          /></a>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped>
.section-footer__contact-container {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.section-footer__contact {
  border-left: 3px solid var(--base-yellow);
  padding-left: 2rem;
}

.section-footer__logo {
  width: 18rem;
  height: 18rem;
}

.section-footer__contact p {
  font-size: 1.8rem;
  font-weight: 300;
  letter-spacing: 1rem;
  opacity: 0.5;
}

.section-footer__contact h3 {
  font-size: 2rem;
}

.section-footer__contact h4 {
  font-size: 1.8rem;
  font-weight: 500;
}

.section-footer__contact h4:first-of-type {
  margin-top: 1rem;
}

.section-footer__contact h4 a:link,
.section-footer__contact h4 a:visited {
  color: var(--base-yellow);
  text-decoration: none;

  display: flex;
  gap: 1rem;
  align-items: center;

  transition: 0.3s ease;
}

.section-footer__contact h4 a:hover {
  filter: drop-shadow(0 0 0.1rem var(--base-yellow));
}

.section-footer__contact h4 a img {
  width: 2rem;
  height: 2rem;
}

.section-footer__socials {
  margin-top: 1.5rem;
  list-style: none;
  display: flex;
  gap: 1rem;
}

.section-footer__socials li a img {
  width: 3.5rem;
  height: 3.5rem;

  transition: 0.3s ease;
}

.section-footer__socials li a img:hover {
  filter: drop-shadow(0 0 0.2rem var(--base-yellow));
}

/* 900px */
@media only screen and (max-width: 56.25em) {
  footer div img {
    width: 14rem;
  }

  .section-footer__contact p {
    font-size: 1.6rem;
    letter-spacing: 0.8rem;
  }

  .section-footer__contact h3 {
    font-size: 1.8rem;
  }

  .section-footer__contact h4 {
    font-size: 1.6rem;
    font-weight: 500;
  }
}

/* 550px */
@media only screen and (max-width: 34.375em) {
  .section-footer__contact-container {
    flex-direction: column;
  }

  .section-footer__contact {
    border-left: none;
    border-top: 3px solid var(--base-yellow);
    padding-left: 0;
    padding-top: 2rem;
  }
}
</style>
