let logoutTimer;

export default {
  async login(context, payload) {
    const response = await fetch(
      "https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyAYpOaZdyTXio8nQAybCPPzBsWZ5fUg0fo",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: payload.email,
          password: payload.password,
          returnSecureToken: true,
        }),
      }
    );

    const responseData = await response.json();

    if (!response.ok) {
      throw new Error(
        "Błąd logowania, podano błędne dane lub serwis chwilowo nie odpowiada"
      );
    }

    const expiresIn = +responseData.expiresIn * 1000;
    const expirationDate = new Date().getTime() + expiresIn;

    localStorage.setItem("userToken", responseData.idToken);
    localStorage.setItem("userId", responseData.localId);
    localStorage.setItem("tokenExpiration", expirationDate);

    logoutTimer = setTimeout(() => {
      context.dispatch("logout");
    }, expiresIn);

    context.commit("setUser", {
      token: responseData.idToken,
      userId: responseData.localId,
      tolenExpiration: expirationDate,
    });
  },
  autoLogin(context) {
    const token = localStorage.getItem("userToken");
    const userId = localStorage.getItem("userId");
    const tokenExpiration = localStorage.getItem("tokenExpiration");

    const expiresIn = +tokenExpiration - new Date().getTime();

    if (expiresIn < 0) {
      return;
    }

    logoutTimer = setTimeout(() => {
      context.dispatch("logout");
    }, expiresIn);

    if (token && userId) {
      context.commit("setUser", {
        token,
        userId,
      });
    }
  },
  logout(context) {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("tokenExpiration");

    clearTimeout(logoutTimer);
    context.commit("setAutoLogout");

    context.commit("setUser", {
      token: null,
      userId: null,
    });
  },
};
