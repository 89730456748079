<template>
  <section class="portfolio-section">
    <base-container>
      <h3 mode="dark" class="portfolio-section__title">PORTFOLIO</h3>
    </base-container>
    <div class="portfolio-section__container">
      <ul class="portfolio-section__list">
        <portfolio-item
          v-for="project in portfolioProjects"
          :key="project.id"
          :src="project.src"
        ></portfolio-item>
      </ul>

      <div class="portfolio__section-btn">
        <base-button mode="outline-dark" link="portfolio"
          >Przejrzyj portfolio</base-button
        >
      </div>
    </div>
  </section>
</template>

<script>
import PortfolioItem from "./PortfolioItem.vue";

export default {
  components: { "portfolio-item": PortfolioItem },
  data() {
    return {
      portfolioProjects: [],
    };
  },
  created() {
    this.portfolioProjects =
      this.$store.getters["portfolio/getThreeRecentProjects"];
  },
};
</script>

<style scoped>
.portfolio-section__title {
  color: #0c0f15ab;
  font-size: 2rem;
  font-weight: 400;
  letter-spacing: 0.5rem;
  margin-bottom: 3rem;
  text-align: center;
}

.portfolio-section {
  margin-top: 10rem;
  background-color: var(--base-yellow);
  padding: 4rem 0;
  box-shadow: 0.2rem 0.2rem 1rem var(--base-dark-light-1);
}

.portfolio-section__list {
  padding: 0 2rem;
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.portfolio-section__container {
  display: flex;
  flex-direction: column;
  gap: 5rem;
}

.portfolio-section__container div {
  text-align: center;
}

/* 1180px */
@media only screen and (max-width: 73.75em) {
  .portfolio-section__title {
    text-align: center;
  }

  .portfolio-section__list {
    flex-direction: column;
    gap: 3rem;
  }

  .portfolio__section-btn {
    text-align: center;
  }
}

/* 800px */
@media only screen and (max-width: 50em) {
  .portfolio-section__list {
    flex-direction: column;
    gap: 3rem;
  }
}

/* 600px */
@media only screen and (max-width: 37.5em) {
  .portfolio__section-btn a:link,
  .portfolio__section-btn a:visited {
    font-size: 1.6rem;
  }
}
</style>
