<template>
  <section class="mails__section">
    <h2 class="err-message" v-if="err">{{ err }}</h2>
    <div class="control-btns">
      <button
        class="btn"
        @click="changeView('all')"
        :class="{ active: switchView === 'all' }"
      >
        Wszystkie wiadomości
      </button>

      <button
        class="btn"
        @click="changeView('new')"
        :class="{ active: switchView === 'new' }"
        v-if="allMails.length > 0"
      >
        Nowe wiadomości
      </button>
      <button
        class="btn"
        @click="changeView('read')"
        :class="{ active: switchView === 'read' }"
        v-if="allMails.length > 0"
      >
        Przeczytane
      </button>
      <button class="btn" @click="loadEmails">Odśwież</button>
    </div>

    <h2 v-if="allMails.length < 1" class="empty-mailbox">
      Skrzynka odbiorcza jest aktualnie pusta :(
    </h2>

    <transition name="inbox" mode="out-in">
      <div class="spinner" v-if="isLoading">
        <div class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>

      <ul
        v-else-if="switchView === 'all' && !isLoading"
        class="mails__container"
      >
        <mail-item
          v-for="mail in allMails"
          :key="mail.id"
          :mail="mail"
          @click="markAs(!mail.marked, mail.id)"
        ></mail-item>
      </ul>

      <ul
        v-else-if="switchView === 'new' && !isLoading"
        class="mails__container"
      >
        <mail-item
          v-for="mail in receivedMails"
          :key="mail.id"
          :mail="mail"
          @click="markAs(true, mail.id)"
        ></mail-item>
      </ul>

      <ul
        v-else-if="switchView === 'read' && !isLoading"
        class="mails__container"
      >
        <mail-item
          v-for="mail in readMails"
          :key="mail.id"
          :mail="mail"
          @click="markAs(false, mail.id)"
        ></mail-item>
      </ul>
    </transition>
  </section>
</template>

<script>
import MailItem from "./MailItem";

export default {
  components: { "mail-item": MailItem },
  data() {
    return {
      switchView: "all",
      err: "",
      isLoading: false,
    };
  },
  computed: {
    allMails() {
      return this.$store.getters["contact/getAllEmails"];
    },
    receivedMails() {
      return this.$store.getters["contact/getAllEmails"].filter(
        (mail) => !mail.marked
      );
    },
    readMails() {
      return this.$store.getters["contact/getAllEmails"].filter(
        (mail) => mail.marked
      );
    },
  },
  methods: {
    async loadEmails() {
      this.err = "";
      this.isLoading = true;
      try {
        await this.$store.dispatch("contact/downloadEmails");
      } catch (err) {
        this.err = err;
      }
      this.isLoading = false;
    },
    changeView(option) {
      this.switchView = option;
    },
    async markAs(option, mailID) {
      try {
        this.$store.commit("contact/setMarkOnMail", { mailID });
        this.$store.dispatch("contact/markAs", { option, mailID });
      } catch (err) {
        this.err = err;
      }
    },
  },
  created() {
    this.loadEmails();
  },
};
</script>

<style scoped>
.empty-mailbox {
  font-size: 2rem;
  color: var(--base-yellow);
  font-weight: 500;
  text-align: center;
}

button.active {
  background-color: var(--base-yellow);
  color: var(--base-dark);
}

button.active:hover {
  box-shadow: 0 0 0.5rem var(--base-yellow-light-1);
  color: var(--base-dark);
}

.err-message {
  font-size: 2rem;
  color: red;
  font-weight: 500;
  margin-bottom: 2rem;
}

.control-btns {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  margin-bottom: 3rem;
}

.btn {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;

  font-size: 1.6rem;
  font-family: inherit;
  color: var(--base-yellow-light-1);
  padding: 0.8rem 1rem;
  border: 1px solid var(--base-yellow-light-1);
  border-radius: 1rem;

  transition: 0.3s ease;
}

.btn:hover {
  color: var(--base-yellow);
  border-color: var(--base-yellow);
}

.mails__container {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  list-style: none;
  min-height: 25vh;
}

.inbox-enter-from {
  transform: translateX(-5rem);
  opacity: 0;
}

.inbox-enter-to,
.inbox-leave-from {
  transform: translateX(0);
  opacity: 1;
}

.inbox-enter-active,
.inbox-leave-active {
  transition: 0.3s ease;
}

.inbox-leave-to {
  transform: translateX(5rem);
  opacity: 0;
}

.spinner {
  text-align: center;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 120px;
  height: 120px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid var(--base-yellow);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--base-yellow) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* 450px */
@media only screen and (max-width: 28.125em) {
  .control-btns {
    justify-content: center;
    margin-bottom: 6rem;
  }
}
</style>
