<template>
  <section class="editor-page__section">
    <base-container>
      <div class="editor-page__container">
        <div class="editor-page__header">
          <h2>GoatEditor Web</h2>
          <h3>autorski system do zarządzania stroną</h3>
        </div>
        <div class="editor-page__control-btns">
          <button
            @click="changeSelection('posts')"
            :class="{ active: postsSelected }"
          >
            Wszystkie posty
          </button>
          <button
            @click="changeSelection('new-post')"
            :class="{ active: newPostSelected }"
          >
            Dodaj nowy post
          </button>
          <button
            @click="changeSelection('mails')"
            :class="{ active: mailsSelected }"
          >
            Skrzynka odbiorcza
          </button>
        </div>

        <div class="editor-page__editor-sections">
          <transition name="section" mode="out-in">
            <posts-section v-if="postsSelected"></posts-section>
            <new-post-section v-else-if="newPostSelected"></new-post-section>
            <mails-section v-else></mails-section>
          </transition>
        </div>
      </div>
    </base-container>
  </section>
</template>

<script>
import PostsSection from "./PostsSection";
import NewPostSection from "./NewPostSection";
import MailsSection from "./MailsSection/MailsSection.vue";

export default {
  components: {
    "posts-section": PostsSection,
    "new-post-section": NewPostSection,
    "mails-section": MailsSection,
  },
  data() {
    return {
      postsSelected: true,
      newPostSelected: false,
      mailsSelected: false,
    };
  },
  methods: {
    changeSelection(category) {
      this.clearSelection();

      if (category === "posts") this.postsSelected = true;
      if (category === "new-post") this.newPostSelected = true;
      if (category === "mails") this.mailsSelected = true;

      return;
    },
    clearSelection() {
      this.postsSelected = false;
      this.newPostSelected = false;
      this.mailsSelected = false;
    },
  },
};
</script>

<style scoped>
.editor-page__section {
  padding: 20rem 0;
  background-color: var(--base-dark);
  margin-bottom: -10rem;
}

.editor-page__container {
  display: flex;
  flex-direction: column;
  gap: 5rem;
}

.editor-page__header {
  text-align: center;
}

.editor-page__header h2 {
  font-size: clamp(2.4rem, 10vw, 10rem);
  font-weight: 600;
  letter-spacing: 1rem;
  background-image: linear-gradient(
    to right,
    var(--base-yellow),
    var(--base-yellow)
  );

  background-clip: text;
  -webkit-text-stroke-color: transparent;
  -webkit-text-stroke-width: 2px;
  -webkit-background-clip: text;
  color: #1b1e24;
}

.editor-page__header h3 {
  font-size: clamp(1.2rem, 10vw, 1.6rem);
  letter-spacing: 1rem;
  color: #ffffff38;
  font-weight: 500;
  margin-top: -1rem;
}

.editor-page__control-btns {
  margin: 1rem 0 6rem 0;
  display: flex;
  gap: 4rem;
}

.editor-page__control-btns button {
  cursor: pointer;
  padding: 0.5rem 1rem;
  font-size: 1.7rem;
  font-weight: 500;
  letter-spacing: 0.2rem;
  color: var(--base-yellow-light-1);
  border: 1px solid var(--base-yellow-light-1);
  background: none;

  transition: 0.2s ease;
}

.editor-page__control-btns button:hover {
  color: var(--base-yellow);
  border-color: var(--base-yellow);
}

.editor-page__control-btns button.active {
  background-color: var(--base-yellow);
  color: var(--base-dark);
}

.section-enter-from {
  transform: translateX(-5rem);
  opacity: 0;
}

.section-enter-to,
.section-leave-from {
  transform: translateX(0);
  opacity: 1;
}

.section-enter-active,
.section-leave-active {
  transition: 0.3s ease;
}

.section-leave-to {
  transform: translateX(5rem);
  opacity: 0;
}

/* 700px */
@media only screen and (max-width: 43.75em) {
  .editor-page__header h3 {
    font-size: 1.2rem;
    letter-spacing: 0.7rem;
    margin-top: 0;
  }

  .editor-page__control-btns {
    gap: 2rem;
    flex-wrap: wrap;
    justify-content: center;
  }

  .editor-page__control-btns button {
    padding: 0.4rem 0.7rem;
    font-size: 1.5rem;
  }
}

/* 600px */
@media only screen and (max-width: 37.5em) {
  .editor-page__section {
    padding: 15rem 0 20rem 0;
  }
}

/* 450px */
@media only screen and (max-width: 28.125em) {
  .editor-page__header h3 {
    letter-spacing: 0.2rem;
    font-size: 1rem;
  }
}
</style>
