<template>
  <section class="offers-page__section">
    <base-container>
      <ul class="offers-page__section-list">
        <li>
          <h2>
            <strong>Zaprojektowanie i stworzenie strony internetowej</strong>
          </h2>
          <p>
            Jako doświadczony twórca stron internetowych, oferuję kompleksowe
            usługi projektowania i tworzenia nowoczesnych stron, które skupiają
            się na doskonałym wrażeniu użytkownika i zgodności z najnowszymi
            standardami technologicznymi
          </p>
          <ul>
            <li>
              Analiza wymagań: Rozpoczynamy proces od konsultacji z klientem,
              aby zrozumieć jego unikalne potrzeby i cele biznesowe. Przyjmuję
              wszelkie sugestie od klienta i staram się dostosować projekt do
              jego wizji
            </li>
            <li>
              Prototypowanie: Na podstawie zebranych informacji, tworzę
              prototypy stron, które pozwalają klientowi wizualnie zrozumieć
              wygląd i funkcjonalność projektowanej strony. Pozwala to na
              wczesne wykrycie i rozwiązanie potencjalnych problemów
            </li>
            <li>
              Wykorzystanie najnowszych technologii: Wdrażam strony internetowe,
              korzystając z zaawansowanych technologii, aby zapewnić
              responsywność i kompatybilność na różnych urządzeniach
            </li>
            <li>
              Estetyka i UI/UX: Skupiam się na zapewnieniu atrakcyjnego designu
              oraz intuicyjnej nawigacji, aby użytkownicy mogli łatwo odnaleźć
              potrzebne informacje i korzystać z witryny bez problemów
            </li>
            <li>
              Testowanie i poprawki: Przed wdrożeniem strony przeprowadzam
              szczegółowe testy, aby upewnić się, że wszystkie elementy działają
              poprawnie i są zgodne ze standardami
            </li>
          </ul>
        </li>

        <li>
          <h2>
            <strong>Zaprojektowanie i stworzenie sklepu internetowego</strong>
          </h2>
          <p>
            Oferuję profesjonalne usługi projektowania i tworzenia sklepów
            internetowych, które pomagają klientom osiągnąć sukces w sprzedaży
            online
          </p>
          <ul>
            <li>
              Wybór odpowiedniego systemu: Współpracuję z klientem, aby wybrać
              najlepszy system e-commerce dla jego biznesu, takie jak WordPress
              z integracją WooCommerce, zapewniając łatwe zarządzanie sklepem i
              produktami.
            </li>
            <li>
              Personalizacja i branding: Dopasowuję wygląd sklepu do wizerunku
              marki klienta, dbając o spójność z identyfikacją wizualną i
              nadając unikalny charakter sklepowi
            </li>
            <li>
              Funkcjonalność e-commerce: Zapewniam pełną funkcjonalność
              e-commerce, w tym zarządzanie produktami, kategoriami, koszykiem,
              płatnościami i systemem do śledzenia zamówień
            </li>
            <li>
              Bezpieczeństwo i certyfikat SSL: Dbam o bezpieczeństwo danych
              klientów, instalując odpowiednie certyfikaty SSL, które
              zabezpieczają dane przesyłane między klientem a sklepem
            </li>
          </ul>
        </li>

        <li>
          <h2>
            <strong>Zaprojektowanie i stworzenie aplikacji webowej</strong>
          </h2>
          <p>
            Tworzenie nowoczesnych i interaktywnych aplikacji webowych jest moją
            specjalnością, a dzięki Vue i React, dostarczam klientom wydajne i
            efektywne rozwiązania
          </p>
          <ul>
            <li>
              Interaktywny interfejs: Wykorzystuję moc Vue i React, aby tworzyć
              interaktywne interfejsy użytkownika, które zapewniają płynne i
              dynamiczne doświadczenia użytkowników
            </li>
            <li>
              Zgodność z różnymi urządzeniami: Moje aplikacje są w pełni
              responsywne, co oznacza, że doskonale działają na komputerach,
              tabletach i smartfonach
            </li>
            <li>
              Optymalizacja wydajności: Stawiam na optymalizację kodu i zasobów,
              aby aplikacje były szybkie i efektywne w działaniu, co przekłada
              się na wyższą satysfakcję użytkowników
            </li>
            <li>
              Testowanie i wsparcie: Przed wdrożeniem, przeprowadzam testy, aby
              upewnić się, że aplikacja działa bez zarzutu. Zapewniam również
              wsparcie po wdrożeniu, aby sprostać wszelkim wyzwaniom
            </li>
          </ul>
        </li>

        <li>
          <h2><strong>Hosting, domena, certyfikat SSL</strong></h2>
          <p>
            Współpracuję z renomowanymi dostawcami usług hostingowych, co
            pozwala mi oferować klientom wydajny i niezawodny hosting oraz
            kompleksową obsługę domen i certyfikatów SSL
          </p>
          <ul>
            <li>
              Wybór odpowiedniego hostingu: Doradzam klientom w wyborze
              odpowiedniego typu hostingu, który najlepiej odpowiada ich
              potrzebom, zapewniając odpowiednią wydajność i skalowalność
            </li>
            <li>
              Rejestracja domeny: Pomagam klientom w procesie rejestracji
              odpowiedniej domeny, która doskonale odzwierciedla nazwę i cel
              biznesu
            </li>
            <li>
              Certyfikat SSL: Dbam o bezpieczeństwo danych klientów, instalując
              certyfikaty SSL, co gwarantuje szyfrowanie przesyłanych danych i
              buduje zaufanie klientów do witryny
            </li>
          </ul>
        </li>

        <li>
          <h2>
            <strong>Optymalizacja SEO, optymalizacja w wyszukiwarce</strong>
          </h2>
          <p>
            Moje usługi nie kończą się na stworzeniu strony - dbam także o to,
            aby strony klientów były zauważalne w wynikach wyszukiwania, poprzez
            optymalizację SEO
          </p>
          <ul>
            <li>
              Audyt SEO: Przeprowadzam kompleksowy audyt SEO, aby zidentyfikować
              słabe punkty i określić, jakie działania są niezbędne do poprawy
              widoczności strony w wynikach wyszukiwania
            </li>
            <li>
              Optymalizacja treści: Pomagam klientom w optymalizacji treści na
              stronie, w tym dobór odpowiednich słów kluczowych, które pozwolą
              na osiągnięcie wyższych pozycji w wynikach wyszukiwania
            </li>
            <li>
              Link building: Wspieram klientów w budowie linków wewnętrznych i
              zewnętrznych, co pozytywnie wpływa na pozycje w wynikach
              wyszukiwania
            </li>
            <li>
              Analiza konkurencji: Śledzę konkurencję, aby dostosować strategię
              SEO klienta i wyróżnić się na rynku
            </li>
          </ul>
        </li>

        <li>
          <h2><strong>Stworzenie wizytówki Google</strong></h2>
          <p>
            Oferuję usługi tworzenia i optymalizacji wizytówek Google, które są
            kluczowe dla lokalnego marketingu i zwiększenia widoczności w
            wynikach lokalnych wyszukiwarek
          </p>
          <ul>
            <li>
              Utworzenie wizytówki Google: Tworzę dokładne i atrakcyjne
              wizytówki Google, zawierające wszystkie niezbędne informacje,
              takie jak adres, godziny otwarcia, numer telefonu itp.
            </li>
            <li>
              Optymalizacja wizytówki: Dbam o to, aby wizytówki klientów były
              zoptymalizowane pod kątem wyświetlania się w wynikach lokalnych,
              co pomaga zdobyć nowych klientów
            </li>
            <li>
              Zdjęcia i opinie: Zachęcam klientów do dodawania pozytywnych
              opinii i dodawania atrakcyjnych zdjęć, aby zwiększyć zaufanie
              potencjalnych klientów
            </li>
          </ul>
        </li>
      </ul>
    </base-container>
  </section>
</template>

<style scoped>
.offers-page__section {
  padding: 20rem 0;
  width: 100%;
  background: url(../imgs/why-section-bg.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-bottom: -10rem;
}

.offers-page__section-list {
  margin-top: 4rem;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  flex-wrap: wrap;
  justify-content: space-between;
}

.offers-page__section-list li {
  padding: 1rem;
  background-color: var(--base-dark);
  border-radius: 2rem;
  box-shadow: 0 0 0.8rem var(--base-dark-light-2);
  transition: 0.3s ease;
}

.offers-page__section-list > li:hover {
  box-shadow: 0 0 1rem var(--base-dark-light-2);
  transform: scale(1.01);
}

.offers-page__section-list > li:hover h2 {
  filter: drop-shadow(0 0 0.2rem var(--base-yellow));
}

.offers-page__section-list li h2 {
  font-size: 3.2rem;
  color: var(--base-yellow);
  font-weight: 500;
  transition: 0.3s ease;
}

.offers-page__section-list li h2 strong {
  font-weight: 500;
}

.offers-page__section-list li p {
  color: #ffffff;
  font-size: 1.8rem;
  width: 60%;
  margin-top: 1rem;
}

.offers-page__section-list li ul {
  margin-top: 3rem;

  background-color: var(--base-yellow);
  list-style-type: decimal;
  color: var(--base-dark);
  font-size: 1.8rem;
  padding: 1rem 1rem 1rem 3rem;
  border-radius: 1rem;
  box-shadow: 0 0 0.8rem var(--base-yellow-light-1);
}

.offers-page__section-list li ul li {
  background: none;
  box-shadow: none;
  border: none;
  width: 80%;
}

/* 900px */
@media only screen and (max-width: 56.25em) {
  .offers-page__section {
    padding-top: 15rem;
  }
}

/* 800px */
@media only screen and (max-width: 50em) {
  .offers-page__section-list li h2 {
    font-size: 2.8rem;
  }

  .offers-page__section-list li p {
    font-size: 1.6rem;
    width: 100%;
  }

  .offers-page__section-list li ul {
    font-size: 1.6rem;
  }

  .offers-page__section-list li ul li {
    width: 100%;
  }
}

/* 500px */
@media only screen and (max-width: 31.25em) {
  .offers-page__section-list li h2 {
    font-size: 2.4rem;
  }
}
</style>
