<template>
  <ul class="about-page__list about-page__list-tools">
    <li>
      <img
        src="../../../imgs/icons/techs/webpack.webp"
        alt="Webpack icon"
        loading="lazy"
      />
      <p>Webpack</p>
    </li>
    <li>
      <img
        src="../../../imgs/icons/techs/parcel.webp"
        alt="Parcel icon"
        loading="lazy"
      />
      <p>Parcel</p>
    </li>
    <li>
      <img
        src="../../../imgs/icons/techs/git.webp"
        alt="Git icon"
        loading="lazy"
      />
      <p>Git</p>
    </li>
    <li>
      <img
        src="../../../imgs/icons/techs/github.webp"
        alt="GitHub icon"
        loading="lazy"
      />
      <p>GitHub</p>
    </li>
    <li>
      <img
        src="../../../imgs/icons/techs/npm.webp"
        alt="NPM icon"
        loading="lazy"
      />
      <p>NPM</p>
    </li>
    <li>
      <img
        src="../../../imgs/icons/techs/firebase.webp"
        alt="Firebase icon"
        loading="lazy"
      />
      <p>Firebase</p>
    </li>
    <li>
      <img
        src="../../../imgs/icons/techs/api.webp"
        alt="API icon"
        loading="lazy"
      />
      <p>API</p>
    </li>
  </ul>
</template>
