<template>
  <section class="coop-section">
    <base-container>
      <div class="coop-section__container">
        <h2 class="coop-section__header">
          Dlaczego <span>warto</span> ze mną <span>współpracować?</span>
        </h2>
        <ul class="coop-section__list">
          <li>
            <h2>Zaangażowanie</h2>
            <p>
              Poświęcam pełną uwagę każdemu projektowi. Nie tylko słucham
              uważnie potrzeb i oczekiwań klienta, ale również angażuje się w
              dogłębne zrozumienie ich branży i celów.
            </p>
          </li>
          <li>
            <h2>Podejście</h2>
            <p>
              Sprostam każdemu wyzwaniu. Nowoczesne podejście i sposób myślenia
              towarzyszy przy każdym moim projekcie.
            </p>
          </li>
          <li>
            <h2>Kreatywność</h2>
            <p>
              Śledzę trendy, ale to JA wyznaczam jaki cel będzie osiągniety. Mój
              styl to wizytówka.
            </p>
          </li>
        </ul>

        <div class="coop-section__btn">
          <base-button mode="outline" link="o-mnie"
            >Dowiedz się więcej</base-button
          >
        </div>
      </div>
    </base-container>
  </section>
</template>

<style scoped>
.coop-section {
  margin-top: 10rem;
}

.coop-section__container {
  background-color: var(--base-dark);
  padding: 4rem 2rem;
  text-align: center;
  border-radius: 15rem;

  box-shadow: 0 0 1rem var(--base-dark);
}

.coop-section__container h2 span {
  background-color: var(--base-yellow);
  color: var(--base-dark);
  padding: 0 0.5rem;
}

.coop-section__header {
  font-size: 4rem;
  font-weight: 600;
  color: var(--base-yellow);
  margin-bottom: 8rem;
}

.coop-section__list {
  display: flex;
  gap: 2rem;
  justify-content: center;
  list-style: none;
}

.coop-section__list li {
  color: var(--base-yellow);
  padding: 2rem 1rem;
  border: 1px solid var(--base-yellow-light-1);
  width: 30%;
  border-radius: 1rem;

  transition: 0.3s ease;
}

.coop-section__list li:hover {
  background-color: var(--base-yellow);
  transform: scale(1.02);
  box-shadow: 0 0 1rem var(--base-yellow-light-1);
}

.coop-section__list li:hover h2 {
  color: var(--base-dark);
}

.coop-section__list li:hover p {
  color: var(--base-dark);
}

.coop-section__list li p {
  margin-top: 1rem;
  font-size: 1.8rem;
  color: #fff;

  transition: 0.3s ease;
}

.coop-section__list h2 {
  font-size: 3rem;
  font-weight: 400;
  color: var(--base-yellow);

  transition: 0.3s ease;
}

.coop-section__btn {
  margin-top: 8rem;
}

/* 900px */
@media only screen and (max-width: 56.25em) {
  .coop-section__container {
    border-radius: 2.5rem;
  }

  .coop-section__header {
    margin-bottom: 4rem;
  }

  .coop-section__list li p {
    font-size: 1.6rem;
  }

  .coop-section__list h2 {
    font-size: 2.4rem;
  }

  .coop-section__btn {
    margin-top: 4rem;
  }

  .coop-section__btn a:link,
  .coop-section__btn:visited {
    font-size: 1.6rem;
  }
}

/* 700px */
@media only screen and (max-width: 43.75em) {
  .coop-section__header {
    font-size: 3rem;
    margin-bottom: 4rem;
  }

  .coop-section__list {
    gap: 2.6rem;
    flex-direction: column;
    align-items: center;
  }

  .coop-section__list li {
    width: 80%;
  }
}

/* 440px */
@media only screen and (max-width: 27.5em) {
  .coop-section__list li {
    width: 100%;
  }
}
</style>
