<template>
  <section class="login-page__section">
    <base-container>
      <div class="login-page__container">
        <div class="lds-ring" v-if="isLoading && !isLoggedIn">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <h2 class="logged-message" v-else-if="isLoggedIn && !isLoading">
          Zalogowany/a
        </h2>
        <form v-else action="POST" @submit.prevent="submitForm">
          <h2>Logowanie</h2>
          <div>
            <input
              type="text"
              name="email"
              id="email"
              :class="{ error: !email.isValid }"
              placeholder="E-mail"
              @input="checkEmail"
              v-model.trim="email.inputValue"
            />
            <input
              type="password"
              name="password"
              id="password"
              :class="{ error: !password.isValid }"
              placeholder="Hasło"
              @input="checkPassword"
              v-model.trim="password.inputValue"
            />
          </div>

          <div class="submit-btn">
            <base-button mode="outline-dark">Zaloguj się</base-button>
          </div>

          <h3 class="error-message" v-if="error">{{ error }}</h3>
        </form>
      </div>
    </base-container>
  </section>
</template>

<script>
export default {
  data() {
    return {
      email: {
        inputValue: "",
        isValid: true,
      },
      password: {
        inputValue: "",
        isValid: true,
      },
      formIsValid: true,
      error: "",
      isLoading: false,
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters["isAuthenticated"];
    },
  },
  methods: {
    checkEmail() {
      if (
        this.email.inputValue.length === 0 ||
        !this.email.inputValue.includes("@")
      )
        this.email.isValid = false;
      else this.email.isValid = true;
    },
    checkPassword() {
      if (this.password.inputValue.length === 0) this.password.isValid = false;
      else this.password.isValid = true;
    },
    formValidation() {
      this.checkEmail();
      this.checkPassword();

      if (this.email.isValid && this.password.isValid) return true;
      return false;
    },
    async submitForm() {
      this.error = "";
      this.isLoading = true;
      if (this.formValidation()) {
        try {
          await this.$store.dispatch("login", {
            email: this.email.inputValue,
            password: this.password.inputValue,
          });

          this.clearInputs();

          this.loggedInMessage = this.$store.getters["isAuthenticated"];
          setTimeout(() => {
            this.$router.replace("/website-editor");
          }, 2000);
        } catch (err) {
          this.error = err;
        }
      }

      this.isLoading = false;
    },
    clearInputs() {
      this.email.inputValue = "";
      this.password.inputValue = "";
    },
  },
};
</script>

<style scoped>
.error,
.error::placeholder {
  color: red !important;
  border-color: red !important;
}

.error-message {
  margin-top: 3rem;
  /* width: 100%; */
  font-size: 1.6rem;
  font-weight: 500;
  color: red;
}

.logged-message {
  font-size: 2rem;
  color: green;
  font-weight: 500;
}

.login-page__section {
  padding: 20rem 0;
  margin-bottom: -10rem;
  height: 85vh;
  background-color: var(--base-dark);
  display: grid;
  place-items: center;
}

.login-page__container {
  background-color: var(--base-yellow);
  display: grid;
  place-items: center;
  padding: 4rem;
  width: 80vw;
  max-width: 50rem;

  border-radius: 2rem;
  box-shadow: 0 0 0.8rem var(--base-dark);
}

form {
  width: 100%;
}

form h2 {
  text-align: center;
  font-size: 2.8rem;
  margin-bottom: 2rem;
}

form div {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

form input {
  outline: none;
  border: none;
  background-color: #a2a2a21e;
  border-bottom: 2px solid var(--base-dark);
  width: 100%;
  padding: 0.5rem;

  color: var(--base-dark);
  font-family: inherit;
  font-size: 1.8rem;
}

form input::placeholder {
  color: var(--base-dark);
  font-family: "Inter Tight", sans-serif;
}

.submit-btn {
  margin-top: 4rem;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid var(--base-dark);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--base-dark) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
