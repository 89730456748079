<template>
  <div class="portfolio-page__item">
    <img
      :src="require(`../../imgs/portfolio/${src}.webp`)"
      :alt="`${title} preview`"
      class="portfolio-page__item-tn"
    />
    <div class="portfolio-page__item-content">
      <h2>{{ title }}</h2>
      <h4>
        {{ desc }}
      </h4>
      <div>
        <ul class="portfolio-page__item-techs">
          <li v-for="tech in techs" :key="tech">{{ tech }}</li>
        </ul>

        <ul class="portfolio-page__item-anchors">
          <li v-if="code !== 'false'">
            <a :href="code" target="_blank"
              >github
              <img
                src="../../imgs/icons/techs/github-yellow.webp"
                alt=""
                loading="lazy"
            /></a>
          </li>

          <li v-if="live !== 'false'">
            <a :href="live" target="_blank"
              >link
              <img src="../../imgs/icons/link.webp" alt="" loading="lazy" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title", "src", "desc", "techs", "live", "code"],
};
</script>

<style scoped>
.portfolio-page__item {
  background-color: var(--base-dark-light-2);
  box-shadow: 0 0 1rem var(--base-dark);
  padding: 1.6rem;
  border-radius: 1rem;

  transition: 0.3s ease;
}

.portfolio-page__item:hover {
  box-shadow: 0 0 0.5rem var(--base-dark);
  transform: scale(1.02);
}

.portfolio-page__item img {
  border-radius: 1rem;
  width: 100%;
  height: auto;
}

.portfolio-page__item-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  height: 25rem;
}

.portfolio-page__item-content div {
  margin-top: auto;
}

.portfolio-page__item-content h2 {
  margin-top: 1rem;
  font-size: 2.4rem;
  color: var(--base-yellow);
  font-weight: 600;
}

.portfolio-page__item-content h4 {
  font-size: 1.6rem;
  color: #fff;
  font-weight: 400;
}

.portfolio-page__item-techs {
  margin-top: 2rem;
  list-style: none;
  display: flex;
  gap: 1rem;
}

.portfolio-page__item-techs li {
  color: var(--base-yellow-light-1);
  font-size: 1.6rem;

  transition: 0.3s ease;
}

.portfolio-page__item-techs li:hover {
  color: var(--base-yellow);
  text-shadow: 0 0 1rem var(--base-yellow);
}

.portfolio-page__item-anchors {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  list-style: none;
}

.portfolio-page__item-anchors li a {
  font-size: 0;
}

.portfolio-page__item-anchors li a img {
  width: 20px;
  height: 20px;

  transition: 0.3s ease;
}

.portfolio-page__item-anchors li a img:hover {
  filter: drop-shadow(0 0 0.2rem var(--base-yellow));
}

/* 685px */
@media only screen and (max-width: 42.8125em) {
  .portfolio-page__item-content {
    height: auto;
  }
}

/* 520px */
@media only screen and (max-width: 32.5em) {
  .portfolio-page__item-content h2 {
    font-size: 2rem;
  }

  .portfolio-page__item-techs li {
    font-size: 1.4rem;
  }
}
</style>
