<template>
  <li class="portfolio-section__list-item">
    <router-link to="portfolio"
      >portfolio
      <img
        :src="require(`../../../imgs/portfolio/${src}.webp`)"
        :alt="'Preview of project ' + src"
        loading="lazy"
      />
    </router-link>
  </li>
</template>

<script>
export default {
  props: ["src"],
};
</script>

<style scoped>
.portfolio-section__list-item {
  position: relative;
  width: 32%;
  border-radius: 3rem;

  overflow: hidden;

  box-shadow: 0.5rem 0.5rem 1rem var(--base-dark-light-1);

  transition: 0.3s ease-out;
}

.portfolio-section__list-item {
  font-size: 0;
}

.portfolio-section__list-item:hover {
  box-shadow: 0.5rem 0.5rem 1rem var(--base-dark-light-2);
  transform: scale(1.02);
}

.portfolio-section__list-item img {
  width: 100%;
  height: 100%;
  transition: 0.3s ease;
}

/* 1180px */
@media only screen and (max-width: 73.75em) {
  .portfolio-section__list-item {
    width: 60%;
  }
}

/* 800px */
@media only screen and (max-width: 50em) {
  .portfolio-section__list-item {
    width: 80%;
  }
}
</style>
