export default {
  setAllEmails(state, payload) {
    state.allEmails = payload;
  },
  setMarkOnMail(state, payload) {
    const mail = state.allEmails.find((mail) => mail.id === payload.mailID);

    mail.marked = !mail.marked;
  },
};
