<template>
  <section class="section-footer">
    <div class="layout-dark"></div>
    <base-container>
      <footer>
        <the-footer-contact></the-footer-contact>
        <the-footer-form></the-footer-form>
      </footer>
    </base-container>
  </section>
</template>

<script>
import TheFooterContact from "./TheFooterContact.vue";
import TheFooterForm from "./TheFooterForm.vue";

export default {
  components: {
    "the-footer-contact": TheFooterContact,
    "the-footer-form": TheFooterForm,
  },
};
</script>

<style scoped>
.section-footer {
  position: relative;
  margin-top: 10rem;
  background-color: var(--base-dark);
  padding: 4rem 0;
  color: var(--base-yellow);
}

.layout-dark {
  position: absolute;
  display: inline-block;
  top: 0;
  left: 0;
  background-color: var(--base-dark);
  opacity: 0.3;
  width: 100%;
  height: 100%;

  background-image: url("../../imgs/contact-section-bg.webp");
  background-size: cover;
  background-repeat: no-repeat;
}

.section-footer footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* 1400px */
@media only screen and (max-width: 87.5em) {
  .layout-dark {
    background-position-x: 50%;
  }
}

/* 750px */
@media only screen and (max-width: 46.875em) {
  .section-footer footer {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    gap: 6rem;
  }

  .layout-dark {
    background-position-x: 60%;
  }
}

/* 550px */
@media only screen and (max-width: 34.375em) {
  .layout-dark {
    background-position-x: 65%;
  }
}
</style>
