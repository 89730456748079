<template>
  <div class="section-footer__form">
    <h3>
      Projektowanie & <br />
      Tworzenie <br />rozwiązań WEBowych
    </h3>
    <router-link to="/kontakt">Formularz kontaktowy</router-link>
  </div>
</template>

<style scoped>
.section-footer__form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4rem;
}

.section-footer__form h3 {
  font-size: 2rem;
  font-weight: 300;
}

.section-footer__form a:link,
.section-footer__form a:visited {
  font-size: 1.8rem;
  color: var(--base-dark);
  background-color: var(--base-yellow);
  text-decoration: none;

  padding: 1rem 3rem;
  border: 1px solid var(--base-yellow);

  border-radius: 2rem;

  transition: 0.3s ease;

  animation: blink 4s infinite;
}

.section-footer__form a:hover {
  animation: none;
  transform: scale(1.02);
  box-shadow: 0 0 1rem var(--base-yellow);
}

.section-footer__form a:active {
  transform: scale(1.02);
  box-shadow: 0 0 0.1rem var(--base-yellow);
  background-color: #ffd900cd;
}

@keyframes blink {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 var(--base-yellow);
  }

  50% {
    transform: scale(1.02);
    box-shadow: 0 0 1rem var(--base-yellow);
  }

  100% {
    transform: scale(1);
    box-shadow: 0 0 0 var(--base-yellow);
  }
}

/* 900px */
@media only screen and (max-width: 56.25em) {
  .section-footer__form h3 {
    font-size: 1.8rem;
  }

  .section-footer__form a:link,
  .section-footer__form a:visited {
    font-size: 1.6rem;
    padding: 1rem 2rem;
    border-radius: 1.8rem;
  }
}
</style>
