<template>
  <div class="notFound-container">
    <base-container>
      <div class="notFound-content">
        <h2>404</h2>
        <h3>
          Strona, której szukasz mogła zostać usunięta, zmieniona lub tymczasowa
          wyłączona
        </h3>
      </div>
      <div class="notFound-btn">
        <base-button link="/" mode="outline">Powrót</base-button>
      </div>
    </base-container>
  </div>
</template>

<style scoped>
.notFound-container {
  width: 100%;
  height: 90vh;
  margin-bottom: -10rem;
  display: grid;
  place-items: center;

  background-color: var(--base-dark);
}

.notFound-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.notFound-content h2 {
  font-size: 30rem;
  background-image: linear-gradient(
    to right,
    var(--base-yellow-light-1),
    var(--base-yellow-light-1)
  );

  background-clip: text;
  -webkit-text-stroke-color: transparent;
  -webkit-text-stroke-width: 3px;
  -webkit-background-clip: text;
  color: #1b1e24;
  letter-spacing: 4rem;
}

.notFound-content h3 {
  font-size: 1.8rem;
  font-weight: 500;
  background-color: var(--base-yellow);
  color: var(--base-dark);
  padding: 2rem;
  margin-top: -20rem;
}

.notFound-btn {
  margin-top: 15rem;
  text-align: center;
}

/* 780px */
@media only screen and (max-width: 48.75em) {
  .notFound-content h2 {
    font-size: 20rem;
    text-align: center;
    letter-spacing: 2rem;
  }

  .notFound-content h3 {
    font-size: 1.6rem;
    margin-top: 0;
  }

  .notFound-btn {
    margin-top: 8rem;
  }
}

/* 580px */
@media only screen and (max-width: 36.25em) {
  .notFound-content h2 {
    font-size: 30vw;
    letter-spacing: 0.5rem;
  }
}
</style>
