<template>
  <div class="app-container">
    <the-header></the-header>
    <router-view v-slot="{ Component }">
      <transition mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>

    <div class="fc">
      <the-footer></the-footer>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.$store.dispatch("autoLogin");
  },
  computed: {
    didAutoLogout() {
      return this.$store.getters.didAutoLogout;
    },
  },
  watch: {
    didAutoLogout(currV, oldV) {
      if (currV && currV !== oldV) {
        if (this.$router.currentRoute._value.path === "/website-editor") {
          this.$router.replace("/login");
        }
      }
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Inter+Tight:wght@200;300;600;700&family=Oswald:wght@300;400;600&family=Roboto:wght@300;400;700&display=swap");
:root {
  --base-yellow: #ffd800;
  --base-yellow-light-1: #ffd90049;
  --base-dark: #0c0f15ef;
  --base-dark-light-1: #0c0f1533;
  --base-dark-light-2: #0c0f1588;
  --base-dark-light-3: #0c0f15e7;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
  font-family: "Inter Tight", sans-serif;
}

body {
  position: relative;
}

.app-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
}

.fc {
  margin-top: auto;
}

.v-enter-from {
  opacity: 0;
  transform: translateY(-5rem);
}

.v-enter-to,
.v-leave-from {
  opacity: 1;
  transform: translateY(0);
}

.v-enter-active {
  transition: 0.3s ease;
}

.v-leave-active {
  transition: 0.3s ease;
}

.v-leave-to {
  transform: translateY(5rem);
  opacity: 0;
}
</style>
