<template>
  <div class="about-page__skills">
    <h2>- Skille -</h2>
    <div class="about-page__skills-container">
      <div class="about-page__skills-btns">
        <button
          @click="changeSelection('techs')"
          :class="{ active: techsSelected }"
        >
          Technologie
        </button>
        <button
          @click="changeSelection('tools')"
          :class="{ active: toolsSelected }"
        >
          Narzędzia
        </button>
        <button
          @click="changeSelection('conventions')"
          :class="{ active: conventionsSelected }"
        >
          Konwencje
        </button>
      </div>

      <transition name="list" mode="out-in">
        <about-page-techs v-if="techsSelected"></about-page-techs>
        <about-page-tools v-else-if="toolsSelected"></about-page-tools>
        <about-page-conventions
          v-else-if="conventionsSelected"
        ></about-page-conventions>
      </transition>
    </div>
  </div>
</template>

<script>
import AboutPageTechs from "./AboutPageLists/AboutPageTechs.vue";
import AboutPageTools from "./AboutPageLists/AboutPageTools.vue";
import AboutPageConventions from "./AboutPageLists/AboutPageConventions.vue";

export default {
  components: {
    "about-page-techs": AboutPageTechs,
    "about-page-tools": AboutPageTools,
    "about-page-conventions": AboutPageConventions,
  },
  data() {
    return {
      techsSelected: true,
      toolsSelected: false,
      conventionsSelected: false,
    };
  },
  methods: {
    changeSelection(category) {
      this.clearSelection();

      if (category === "techs") this.techsSelected = true;
      if (category === "tools") this.toolsSelected = true;
      if (category === "conventions") this.conventionsSelected = true;

      return;
    },
    clearSelection() {
      this.techsSelected = false;
      this.toolsSelected = false;
      this.conventionsSelected = false;
    },
  },
};
</script>

<style>
.about-page__skills-container {
  height: 25rem;
}

.about-page__skills {
  margin-top: 10rem;
}

.about-page__skills h2 {
  font-size: 3rem;
  color: var(--base-yellow);
  font-weight: 500;
  letter-spacing: 0.5rem;
}

.about-page__skills-btns {
  margin: 1rem 0 6rem 0;
  display: flex;
  gap: 4rem;
}

.about-page__skills-btns button {
  cursor: pointer;
  padding: 0.5rem 1rem;
  font-size: 1.7rem;
  font-weight: 500;
  letter-spacing: 0.2rem;
  color: var(--base-yellow-light-1);
  border: 1px solid var(--base-yellow-light-1);
  background: none;

  transition: 0.2s ease;
}

.about-page__skills-btns button:hover {
  color: var(--base-yellow);
  border-color: var(--base-yellow);
}

.about-page__skills-btns button.active {
  background-color: var(--base-yellow);
  color: var(--base-dark);
}

.about-page__list {
  margin-top: 3rem;
  display: flex;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(14rem, 1fr));
  gap: 3rem;
  list-style: none;
}

.about-page__list li {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;

  padding: 1rem 2rem;
  border: 1px solid var(--base-yellow-light-1);
  color: #fff;
  font-size: 1.6rem;
  font-weight: 300;

  transition: 0.3s ease;
}

.about-page__list img {
  width: 40px;
  height: 40px;

  filter: drop-shadow(0 0 1rem var(--base-dark-light-1));
}

.about-page__list li:hover {
  background-color: var(--base-yellow);
  box-shadow: 0 0 0.5rem var(--base-yellow);
  color: var(--base-dark);
}

.list-enter-from {
  transform: translateX(-5rem);
  opacity: 0;
}

.list-enter-to,
.list-leave-from {
  transform: translateX(0);
  opacity: 1;
}

.list-enter-active,
.list-leave-active {
  transition: 0.3s ease;
}

.list-leave-to {
  transform: translateX(5rem);
  opacity: 0;
}

/* 800px */
@media only screen and (max-width: 50em) {
  .about-page__skills-container {
    height: 35rem;
  }
}

/* 560px */
@media only screen and (max-width: 35em) {
  .about-page__skills-container {
    height: 50rem;
  }

  .about-page__skills-btns {
    margin: 1rem 0 6rem 0;
    display: flex;
    gap: 4rem;
  }

  .about-page__skills-btns {
    flex-wrap: wrap;
    justify-content: center;
  }
}

/* 300px */
@media only screen and (max-width: 18.75em) {
  .about-page__skills-container {
    height: auto;
  }
}
</style>
