<template>
  <button
    v-if="!link"
    class="btn"
    :class="{
      'btn-base': mode === 'base',
      'btn-outline': mode === 'outline',
      'btn-outline-dark': mode === 'outline-dark',
      'btn-horizontal': direction === 'horizontal',
      'btn-vertical': direction === 'vertical',
      'btn-radius-right': radius === 'right',
      'btn-radius-left': radius === 'left',
      'btn-small': size === 'small',
      'btn-normal': size === 'normal',
      'btn-huge': size === 'huge',
    }"
  >
    <slot></slot>
  </button>

  <router-link
    class="btn"
    :class="{
      'btn-base': mode === 'base',
      'btn-outline': mode === 'outline',
      'btn-outline-dark': mode === 'outline-dark',
      'btn-horizontal': direction === 'horizontal',
      'btn-vertical': direction === 'vertical',
      'btn-radius-right': radius === 'right',
      'btn-radius-left': radius === 'left',
      'btn-small': size === 'small',
      'btn-normal': size === 'normal',
      'btn-huge': size === 'huge',
    }"
    v-else
    :to="`/${link}`"
    ><slot></slot
  ></router-link>
</template>

<script>
export default {
  props: {
    mode: {
      type: String,
      required: false,
      default: "base",
    },
    direction: {
      type: String,
      required: false,
      default: "horizontal",
    },
    radius: {
      type: String,
      required: false,
      default: "right",
    },
    size: {
      type: String,
      required: false,
      default: "normal",
    },
    link: {
      type: String,
      required: false,
    },
  },
};
</script>

<style scoped>
.btn {
  background: none;
  border: none;
  font-size: 3rem;
  cursor: pointer;
  text-decoration: none;

  transition: 0.3s ease;
}

.btn-base {
  color: #0c0f15;
  background-color: var(--base-yellow);
}

.btn-base:hover {
  box-shadow: 0 0 0.8rem var(--base-yellow);
}

.btn-outline {
  border: 1px solid var(--base-yellow);
  color: var(--base-yellow);
}

.btn-outline-dark {
  border: 1px solid var(--base-dark);
  color: var(--base-dark);
}

.btn-outline-dark:hover {
  background-color: var(--base-dark);
  color: var(--base-yellow);
  transform: scale(1.02);
  box-shadow: 0 0 1rem var(--base-dark-light-1);
}

.btn-outline-dark:active {
  transform: scale(0.98);
  background-color: var(--base-dark-light-3);
  box-shadow: 0 0 0.6rem var(--base-dark-light-1);
}

.btn-outline:hover {
  color: var(--base-dark);
  background-color: var(--base-yellow);
  transform: scale(1.02);
}

.btn-outline:active,
.btn-base:active {
  background-color: #ecc900;
  transform: scale(0.98);
}

.btn-vertical {
  padding: 4rem 2rem;
}

.btn-horizontal {
  padding: 1rem 2rem;
}

.btn-radius-right {
  border-top-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.btn-radius-right:hover {
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;

  border-top-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.btn-radius-left {
  border-top-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.btn-radius-left:hover {
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;

  border-top-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.btn-small {
  font-size: 1.2rem;
}

.btn-normal {
  font-size: 1.8rem;
}

.btn-huge {
  font-size: 3rem;
}
</style>
